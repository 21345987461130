import { StyledComponentProps } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';

export const UploadIcon = (props: StyledComponentProps) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 21.994H22V19.994H2V21.994ZM5.294 8.69L6.708 10.104L11.001 5.811V18.004H13.001V5.834L17.296 10.106L18.706 8.688L11.983 2L5.294 8.69Z"
    />
  </SvgIcon>
);
