export const UploadStates = {
  Queued: 'Queued',
  Errored: 'Errored',
  Uploading: 'Uploading',
  Canceled: 'Canceled',
  Done: 'Done',
  Processing: 'Processing',
};

// UPLOAD PROCESS STATES FROM API (in addition to processing/queued)
// https://petstore.swagger.io/?url=https://studio-api.qa.shuttercorp.net/api-docs#/dam/getUploadProcess
export const FAILED = 'failed';
export const VIRUS = 'virus';
export const SUCCESS = 'success';
export const TOO_LARGE = 'too large';
export const UNSUPPORTED = 'unsupported';
export const DUPLICATE = 'duplicate';

export const ProcessStates = Object.freeze({
  Failed: 'failed',
  Virus: 'virus',
  Success: 'success',
  Too_Large: 'too large',
  Unsupported: 'unsupported',
  Duplicate: 'duplicate',
} as const);

// ERROR TYPES FOR UPLOADS
export const ERR_NONE = 'none';

export const WidgetStates = Object.freeze({
  Open: Symbol('Open'),
  Closed: Symbol('Closed'),
  Minimized: Symbol('Minimized'),
});

// QUEUE constants
export const MAX_CONCURRENCY = 3;
