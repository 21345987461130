import { useCallback } from 'react';

import { useFetchUser } from '@/hooks/useFetchUser';
import { noop } from '@/utils/noop';

import {
  FILE_SIZE_LIMIT_4GB,
  FILE_SIZE_LIMIT_50MB,
  FILE_SIZE_LIMIT_65MB,
  FILE_SIZE_LIMIT_100MB,
  UPLOADS_VALID_ILLUSTRATION_FILE_TYPES,
  UPLOADS_VALID_IMAGE_FILE_TYPES,
  UPLOADS_VALID_VIDEO_FILE_TYPES,
  VALID_UPLOAD_FILE_TYPES,
} from './constants';
import { useUploadsContext } from './Context';
import { DUPLICATE, TOO_LARGE, UNSUPPORTED } from './Context/constants';

const getFailedReason = (fileTooLarge, unsupportedFiletype, duplicateFileName) => {
  switch (true) {
    case fileTooLarge:
      return TOO_LARGE;
    case unsupportedFiletype:
      return UNSUPPORTED;
    case duplicateFileName:
      return DUPLICATE;
    default:
      return null;
  }
};

export const useFilesHandler = () => {
  const { isOffsetUser } = useFetchUser();

  const { enqueueUpload, enqueueFailedUpload } = useUploadsContext();
  const fileNameArray = [];

  return useCallback(
    (files) => {
      const isValidFileType = (fileType) =>
        isOffsetUser ? UPLOADS_VALID_IMAGE_FILE_TYPES.includes(fileType) : VALID_UPLOAD_FILE_TYPES.includes(fileType);

      const isFileTooLarge = (fileType, fileSize) => {
        if (UPLOADS_VALID_IMAGE_FILE_TYPES.includes(fileType)) {
          return fileSize >= FILE_SIZE_LIMIT_50MB;
        }

        if (UPLOADS_VALID_ILLUSTRATION_FILE_TYPES.includes(fileType)) {
          return fileSize >= FILE_SIZE_LIMIT_100MB;
        }

        if (UPLOADS_VALID_VIDEO_FILE_TYPES.includes(fileType)) {
          return fileSize >= FILE_SIZE_LIMIT_4GB;
        }

        return fileSize >= FILE_SIZE_LIMIT_65MB;
      };

      files.forEach((file) => {
        const unsupportedFiletype = !isValidFileType(file.type) || false;

        const fileTooLarge = isFileTooLarge(file.type, file.size) || false;
        const duplicateFileName = fileNameArray.includes(file.name) || false;

        const failedReason = getFailedReason(fileTooLarge, unsupportedFiletype, duplicateFileName);

        if (fileTooLarge || unsupportedFiletype || duplicateFileName) {
          enqueueFailedUpload({ file, failedReason });
        } else {
          fileNameArray.push(file.name);

          enqueueUpload({
            file,
            postUploadCallback: noop,
          });
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueFailedUpload, enqueueUpload],
  );
};
