import MUICheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import { FC } from 'react';

import { withClickTracking } from '@/lib/analytics/react-data-tracking';

interface ExtendedCheckboxProps extends CheckboxProps {
  trackAction: string;
  target?: string;
}

const UntrackedCheckbox: FC<ExtendedCheckboxProps> = function UntrackedCheckbox(props) {
  return <MUICheckbox {...props} />;
};

export const Checkbox = withClickTracking(UntrackedCheckbox);
