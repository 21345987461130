import { FacebookIcon } from '@/icons/FacebookIcon';
import { InstagramIcon } from '@/icons/InstagramIcon';
import { LinkedinIcon } from '@/icons/LinkedinIcon';
import { PinterestIcon } from '@/icons/PinterestIcon';
import { TwitterIcon } from '@/icons/TwitterIcon';
import { VimeoIcon } from '@/icons/VimeoIcon';
import { YoutubeIcon } from '@/icons/YoutubeIcon';

export const createSocialShareLinks = (url) => [
  {
    href: `https://www.facebook.com/share.php?u=${url}`,
    ariaLabel: 'FACEBOOK',
    IconComponent: FacebookIcon,
  },
  {
    href: `https://twitter.com/intent/tweet?url=${url}`,
    ariaLabel: 'TWITTER',
    IconComponent: TwitterIcon,
  },
  {
    href: `https://www.linkedin.com/shareArticle?mini=true&url=${url}`,
    ariaLabel: 'LINKEDIN',
    IconComponent: LinkedinIcon,
  },
];

export const socialShutterstockLinks = [
  {
    ariaLabel: 'Open Facebook on a new page',
    href: 'https://www.facebook.com/Shutterstock',
    IconComponent: FacebookIcon,
  },
  {
    ariaLabel: 'Open Twitter on a new page',
    href: 'https://twitter.com/Shutterstock',
    IconComponent: TwitterIcon,
  },
  {
    ariaLabel: 'Open Instagram on a new page',
    href: 'https://www.instagram.com/shutterstock',
    IconComponent: InstagramIcon,
  },
  {
    ariaLabel: 'Open Linkedin on a new page',
    href: 'https://www.linkedin.com/company/shutterstock',
    IconComponent: LinkedinIcon,
  },
  {
    ariaLabel: 'Open Youtube on a new page',
    href: 'https://www.youtube.com/c/shutterstock',
    IconComponent: YoutubeIcon,
  },
  {
    ariaLabel: 'Open Vimeo on a new page',
    href: 'https://vimeo.com/shutterstock',
    IconComponent: VimeoIcon,
  },
  {
    ariaLabel: 'Open Pinterest on a new page',
    href: 'https://www.pinterest.ie/shutterstock',
    IconComponent: PinterestIcon,
  },
];
