import { color } from '../tokens';

export const MuiInputOverrides = {
  styleOverrides: {
    root: {
      color: color.gray.onyx,
    },
  },
};

export const MuiInputLabelOverrides = {
  styleOverrides: {
    root: {
      color: color.gray['onyx-60'],
    },
  },
};

export const MuiOutlinedInputOverrides = {
  styleOverrides: {
    input: {
      textOverflow: 'ellipsis',
    },
  },
};
