import Alert, { AlertColor } from '@mui/material/Alert';
import { Theme } from '@mui/material/styles';
import { SnackbarContent, useSnackbar } from 'notistack';
import { ReactNode, useCallback, useMemo } from 'react';

import { ALERT_TYPES, DISABLE_CLICK_AWAY_CLASS } from '@/constants/global';
import { useWarningOrErrorEvent } from '@/hooks/analytics/useWarningOrErrorEvent';
import { capitalizeCase } from '@/utils/caseStyleTransforms';

const notificationStyles = {
  alert: ({ spacing }: Theme) => ({
    padding: `calc(${spacing('base')} - ${spacing('xxs')}) ${spacing('m')}`,
    borderRadius: spacing('base'),
    margin: 0,
  }),
};

/**
 * @typedef NotificationFunction
 * @type {function}
 * @param {string} text The text that is displayed
 * @param {React.ReactNode} [icon] The icon that is displayed
 * @param {number} [hideAfter] Whether or not the notification should hide after a certain amount of time
 * @returns {void}
 */

/**
 * Creates and outputs an object containing display functions for use in displaying a user a notification.
 * @typedef DisplayFunctions
 * @type {Object}
 * @property {NotificationFunction} [displaySuccess] Used for when an operation has completed something expected and successfully.
 * @property {NotificationFunction} [displayError] Used for when an operation has completed something unexpected or unsuccessfully.
 * @property {NotificationFunction} [displayInfo] Used to inform the user of some notable information.
 * @property {NotificationFunction} [displayWarning] Used to inform the user of something not allowed or of critical in nature.
 * @returns {DisplayFunctions} displayFunctions
 */

type UseNotificationsReturn = {
  displaySuccess: (message: string, icon?: ReactNode, hideAfter?: number) => void;
  displayWarning: (message: string, icon?: ReactNode, hideAfter?: number) => void;
  displayError: (message: string, icon?: ReactNode, hideAfter?: number) => void;
  displayInfo: (message: string, icon?: ReactNode, hideAfter?: number) => void;
};

export const useNotifications = (): UseNotificationsReturn => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const warningOrErrorEvent = useWarningOrErrorEvent();

  const enqueueSnackbarWithAnalytics = useCallback(
    ({ text, icon, hideAfter, type }: { text: string; icon: ReactNode; hideAfter: number; type: string }) => {
      if (type === 'error' || type === 'warning') {
        warningOrErrorEvent({ errorType: type, text });
      }

      enqueueSnackbar(text, {
        autoHideDuration: hideAfter || 6000,
        content: (key, message) => (
          <SnackbarContent className={DISABLE_CLICK_AWAY_CLASS} key={key}>
            <Alert
              data-testid={`notification-${type}`}
              variant="filled"
              elevation={6}
              icon={icon}
              severity={type as AlertColor}
              onClose={() => closeSnackbar(key)}
              sx={notificationStyles.alert}
            >
              {message}
            </Alert>
          </SnackbarContent>
        ),
      });
    },
    [warningOrErrorEvent, closeSnackbar, enqueueSnackbar],
  );
  /**
   * @param {string} text The text that is displayed
   */
  const displayFunctions = useMemo(
    () =>
      ALERT_TYPES.reduce(
        (acc, type) => ({
          ...acc,
          [`display${capitalizeCase(type)}`]: (text: string, icon: ReactNode, hideAfter: number) => {
            enqueueSnackbarWithAnalytics({ text, icon, hideAfter, type });
          },
        }),
        {} as UseNotificationsReturn,
      ),
    [enqueueSnackbarWithAnalytics],
  );

  return { ...displayFunctions };
};
