import Cookies from 'js-cookie';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import getConfig from 'next/config';
import { FC, PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  user: any;
}>;

const { publicRuntimeConfig } = getConfig();

export const FeatureFlagsProvider: FC<Props> = ({ children, user }) => {
  const clientSideId = publicRuntimeConfig?.launchdarkly?.clientSideId;

  const anonId = Cookies.get('sstk_anonymous_id');
  const userId = user?.data?.details?.account?.contributorId;

  const context = {
    kind: 'user',
    key: userId || anonId,
    id: userId,
  };

  return (
    <LDProvider
      clientSideID={clientSideId}
      reactOptions={{
        useCamelCaseFlagKeys: false,
      }}
      context={context}
    >
      {children}
    </LDProvider>
  );
};
