import Drawer from '@mui/material/Drawer';
import dynamic from 'next/dynamic';

import { UserDetailsDrawerPageSections } from '@/constants/analytics';
import { PageSection } from '@/lib/analytics/react-data-tracking';

const UserDetailsDrawerContent = dynamic(
  () => import('./UserDetailsDrawerContent').then((mod) => mod.UserDetailsDrawerContent),
  {
    ssr: false,
  },
);

export const UserDetailsDrawer = ({ isActive, toggleDrawer }) => (
  <PageSection value={UserDetailsDrawerPageSections.userDetailsDrawer}>
    <Drawer
      anchor="right"
      data-testid="user-details-drawer"
      onClose={toggleDrawer}
      open={isActive}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <UserDetailsDrawerContent toggleDrawer={toggleDrawer} />
    </Drawer>
  </PageSection>
);
