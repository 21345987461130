import { createContext, useState } from 'react';

import { MENU_ITEMS } from '@/constants/global';

import { itemHasSubMenu } from '../../helpers';

export const DrawerContext = createContext(null);

export const SubMenuDrawerProvider = ({ children }) => {
  const [drawerState, setDrawerState] = useState({
    isDrawerOpen: false,
    drawerContentType: MENU_ITEMS.EARNINGS,
    reOpenDrawerTo: null,
  });
  /**
   * open -> if setDrawer called && drawer is not open && itemHasSubMenu(iconType)
   * stay open -> if iconType is different from previous && itemHasSubMenu(iconType)
   * close -> if !itemHasSubMenu(iconType)
   * close -> if iconType == previousIconType
   */

  const closeDrawer = () => setDrawerState({ ...drawerState, isDrawerOpen: false, reOpenDrawerTo: null });
  const setDrawer = (iconType) =>
    setDrawerState((previousDrawerState) => {
      const { isDrawerOpen, drawerContentType: previousIconType } = previousDrawerState;

      if (!itemHasSubMenu(iconType)) {
        return { isDrawerOpen: false, drawerContentType: previousIconType, reOpenDrawerTo: null };
      }

      // when the drawer is closed, persist drawerContentType so drawer's content
      // doesn't disappear mid-closing
      if (iconType === previousIconType) {
        return { ...drawerState, isDrawerOpen: !isDrawerOpen };
      }

      // if switching to another drawer type, then we close current drawer and reopen
      if (isDrawerOpen) {
        return { isDrawerOpen: false, drawerContentType: previousIconType, reOpenDrawerTo: iconType };
      }

      return { isDrawerOpen: true, drawerContentType: iconType, reOpenDrawerTo: null };
    });

  const checkShouldTransition = () => {
    setDrawerState((previousDrawerState) => {
      const { reOpenDrawerTo } = previousDrawerState;

      return reOpenDrawerTo === null
        ? previousDrawerState
        : { isDrawerOpen: true, drawerContentType: reOpenDrawerTo, reOpenDrawerTo: null };
    });
  };

  const { isDrawerOpen, drawerContentType } = drawerState;

  return (
    <DrawerContext.Provider
      value={{
        drawerContentType,
        isDrawerOpen,
        closeDrawer,
        setDrawer,
        checkShouldTransition,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};
