import { MuiAccordionOverrides } from './MuiAccordion';
import { MuiButtonOverrides } from './MuiButton';
import { MuiChipOverrides } from './MuiChip';
import { MuiOutlinedInputOverrides } from './MuiInput';
import { MuiSkeletonOverrides } from './MuiSkeleton';
import { MuiTooltipOverrides } from './MuiTooltip';
import { MuiTypographyOverrides } from './MuiTypography';
/**
 * Global theme override
 * You can take advantage of the overrides key of the theme
 * to potentially change every single style injected
 * by Material-UI into the DOM.
 *
 * Learn more about it in the themes section of the documentation.
 * https://next.material-ui.com/customization/theme-components
 *  */

export const components = {
  MuiButton: MuiButtonOverrides,
  MuiChip: MuiChipOverrides,
  MuiTypography: MuiTypographyOverrides,
  MuiAccordion: MuiAccordionOverrides,
  MuiTooltip: MuiTooltipOverrides,
  MuiOutlinedInput: MuiOutlinedInputOverrides,
  MuiSekeleton: MuiSkeletonOverrides,
};
