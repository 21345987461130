import { font } from './tokens';

// htmlFontSize is default 16px,
// fontSize is default 14px,

export const typography = {
  fontFamily: `${font.fontFamily}, Helvetica, Arial`,
  h1: { fontWeight: font.weight.bold, fontSize: font.size.h1, lineHeight: font.lineHeight.h1 },
  h2: { fontWeight: font.weight.bold, fontSize: font.size.h2, lineHeight: font.lineHeight.h2 },
  h3: { fontWeight: font.weight.bold, fontSize: font.size.h3, lineHeight: font.lineHeight.h3 },
  h4: { fontWeight: font.weight.bold, fontSize: font.size.h4, lineHeight: font.lineHeight.h4 },
  h5: { fontWeight: font.weight.bold, fontSize: font.size.large, lineHeight: font.lineHeight.large }, // No design tokens for h5, Used for section titles
  h6: { fontWeight: font.weight.bold, fontSize: font.size.regular, lineHeight: font.lineHeight.regular }, // No design tokens for h6.
  sectionTitle: { fontWeight: font.weight.bold, fontSize: font.size.large, lineHeight: font.lineHeight.large }, // large & bold
  subtitle1: { fontWeight: font.weight.normal, fontSize: font.size.small, lineHeight: font.lineHeight.small }, // small
  subtitle2: { fontWeight: font.weight.normal, fontSize: font.size.regular, lineHeight: font.lineHeight.regular }, // regular
  body1: { fontWeight: font.weight.normal, fontSize: font.size.regular, lineHeight: font.lineHeight.regular }, // regular
  body2: { fontWeight: font.weight.normal, fontSize: font.size.small, lineHeight: font.lineHeight.small }, // small
  caption: { fontWeight: font.weight.normal, fontSize: font.size.regular, lineHeight: font.lineHeight.regular }, // regular
  bodyStaticLg: {
    // large
    fontWeight: font.weight.normal,
    fontSize: font.size.large,
    lineHeight: font.lineHeight.large,
  },
  bodyBoldLg: {
    // large
    fontWeight: font.weight.bold,
    fontSize: font.size.large,
    lineHeight: font.lineHeight.large,
  },
  bodyStaticMd: {
    // regular
    fontWeight: font.weight.normal,
    fontSize: font.size.regular,
    lineHeight: font.lineHeight.regular,
  },
  bodyBoldMd: {
    // regular
    fontWeight: font.weight.bold,
    fontSize: font.size.regular,
    lineHeight: font.lineHeight.regular,
  },
  bodyStaticSm: {
    // small
    fontWeight: font.weight.normal,
    fontSize: font.size.small,
    lineHeight: font.lineHeight.small,
  },
  bodyBoldSm: {
    // small
    fontWeight: font.weight.bold,
    fontSize: font.size.small,
    lineHeight: font.lineHeight.small,
  },
  bodyStaticXs: {
    // micro
    fontWeight: font.weight.normal,
    fontSize: font.size.micro,
    lineHeight: font.lineHeight.micro,
  },
  bodyBoldXs: {
    // micro
    fontWeight: font.weight.bold,
    fontSize: font.size.micro,
    lineHeight: font.lineHeight.micro,
  },
  fontWeightLight: font.weight.normal,
  fontWeightRegular: font.weight.normal,
  fontWeightMedium: font.weight.normal,
  fontWeightBold: font.weight.bold,
  htmlFontSize: parseInt(font.baseFontSize, 10),
};

// DEFAULT FONT SIZES FOR HTML ELEMENTS IN MUI:
//
// h1: { fontSize: 6rem // 90px }
// h2: { fontSize: 3.75rem // 56.25px }
// h3: { fontSize: 3rem // 45px }
// h4: { fontSize: 2.125rem // 31.875px }
// h5: { fontSize: 1.5rem // 22.5px }
// h6: { fontSize: 1.25rem // 18.75px }
// subtitle1: { fontSize: 1rem // 15px, lineHeight: 1.75 }
// subtitle2: { fontSize: 0.875rem // 13.125px }
// body1: { fontSize: 1rem // 15px, lineHeight: 1.5 }
// body2: { fontSize: 0.875rem // 13.125px, lineHeight: 1.43 }
// button: { fontSize: 0.875rem // 13.125px }
// caption: { fontSize: 0.75rem // 11.25px }
//

// DEFAULT FONT SIZES FOR HTML ELEMENTS IN SSTK:
//
// h1: { font-size: var(--font-size-lgr) /* 64/70px */ 4rem }
// h2: { font-size: var(--font-size-xxxl) /* 48/53px */ 3.067rem }
// h3: { font-size: var(--font-size-xxl) /* 30/33px */ 1.933rem }
// h4: { font-size: var(--font-size-xl) /* 24/26px */ 1.533rem }
// h5: { font-size: var(--font-size-lg) /* 20/22px */ 1.267rem }
// h6: { font-size: var(--font-size-default) /* 16px */ 1rem }
//

// SPECS FOR SOME OXYGEN VARIABLES:
// .font-body-static-lg {
//   font-size: var(--font-size-lg); /* 20/30px */ 1.267rem
// }
// .font-body-static-md {
//   font-size: var(--font-size-default); /* 16/24px */ 1rem
// }
// .font-body-static-sm {
//   font-size: var(--font-size-sm); /* 14/21px */ 0.867rem
// }
// .font-body-static-xs {
//   font-size: var(--font-size-xs); /* 12/18px */ 0.733rem
// }
// --font-size-xs: var(--token-base-font-size-xs); 0.733rem
// --font-size-sm: var(--token-base-font-size-s); 0.867rem
// --font-size-default: var(--token-base-font-size-m); 1rem
// --font-size-md: var(--token-base-font-size-l); 1.133rem
// --font-size-lg: var(--token-base-font-size-xl); 1.267rem
// --font-size-xl: var(--token-base-font-size-xxl); 1.533rem
// --font-size-xxl: var(--token-base-font-size-3xl); 1.933rem
// --font-size-xxxl: var(--token-base-font-size-4xl); 3.067rem
// --font-size-lgr: var(--token-base-font-size-5xl); 4rem
