import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { useTheme } from '@mui/material/styles';

import { Copyright } from '@/components/Copyright';
import { LanguageSelect } from '@/components/LanguageSelect';
import { MobileLinks } from '@/components/MobileLinks';
import { SocialMediaShareIcons, socialShutterstockLinks } from '@/components/SocialMediaShareIcons';

import { footerData } from './constants';
import { DesktopLinks } from './DesktopLinks';
import { StatsInfo } from './StatsInfo';
import { createStyles } from './styles';

export const SiteFooter = () => {
  const classes = createStyles(useTheme());

  return (
    <Grid id="SiteFooter" sx={classes.root}>
      <Container sx={classes.container} maxWidth="lg">
        <Grid container rowSpacing={2}>
          <Grid item xs={12} md={10}>
            <StatsInfo />
          </Grid>
          <Grid item xs={12} md={2}>
            <LanguageSelect />
          </Grid>
        </Grid>
        <Grid container>
          {footerData.map((footerColumn) => (
            <Grid key={footerColumn.label} item xs={12} md={3}>
              <Hidden mdUp implementation="css">
                <MobileLinks
                  headerLabel={footerColumn.headerTranslationKey}
                  links={footerColumn.links}
                  target={footerColumn.target}
                />
              </Hidden>
              <Hidden mdDown implementation="css">
                <DesktopLinks headerLabel={footerColumn.headerTranslationKey} links={footerColumn.links} />
              </Hidden>
            </Grid>
          ))}
        </Grid>

        <Hidden mdDown implementation="css">
          <Box sx={classes.footerBorder} />
        </Hidden>

        <Box sx={classes.copyrightSocialMediaIconsContainer}>
          <Copyright />
          <SocialMediaShareIcons
            iconData={socialShutterstockLinks}
            iconOptions={{ color: 'link.footer', hoverColor: 'link.contrastText' }}
            sx={classes.socialMediaIconsContainer}
          />
        </Box>
      </Container>
    </Grid>
  );
};
