import { format } from 'url';

import { ACCOUNT_SETTINGS_URL, CURRENT_USER_URL } from '@/constants/api';

export const getCurrentUser = () => ({
  url: CURRENT_USER_URL,
  formattedUrl: format({ pathname: CURRENT_USER_URL }),
});

export const getCurrentUserSettings = () => ({
  url: ACCOUNT_SETTINGS_URL,
});
