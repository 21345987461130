import Skeleton from '@mui/material/Skeleton';
import type { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { UploaderDialog } from '@/components/Portfolio/UploaderDialog/UploaderDialog';
import { Button } from '@/components/WithTracking/Button';
import { CommonTrackActions } from '@/constants/analytics';
import { LINK_TYPE, NON_C2N_ROUTES } from '@/constants/global';
import { useFetchUploadingOk } from '@/hooks/useFetchUploadingOk';
import { usePortfolioNextExp } from '@/hooks/usePortfolioNextExp';
import { useUploadingDisabledNotification } from '@/hooks/useUploadingDisabledNotification';
import { PlusIcon } from '@/icons/PlusIcon';
import { NAMESPACE_COMMON } from '@/translations/namespaces';
import { formatLink } from '@/utils/url';

const mobileUploadButtonStyle = ({ spacing }: Theme) => ({
  p: spacing(2.7),
  minWidth: spacing(1),
  width: spacing(1),
});

const createUploadMenuUrls = (locale: string | undefined) => ({
  ccWebUploadUrl: formatLink({
    href: NON_C2N_ROUTES.UPLOAD_PORTFOLIO_PAGE,
    linkType: LINK_TYPE.CCWEB_REDIRECT,
    locale,
  }),
});
const getTestIds = (isMobile: boolean) => (isMobile ? 'mobile-upload-button' : 'desktop-upload-button');

const UploadButtonSkeleton = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  if (isMobile) {
    return <Skeleton variant="circular" sx={{ ml: 1 }} width={40} height={40} />;
  }

  return <Skeleton variant="rectangular" sx={{ borderRadius: 6 }} width={125} height={38} />;
};

export const UploadButton = () => {
  const isPortfolio = usePortfolioNextExp();
  const { locale } = useRouter();
  const { ccWebUploadUrl } = createUploadMenuUrls(locale);
  const { t } = useTranslation(NAMESPACE_COMMON);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { isLoading, uploadingOk: isUploadingAllowed = false } = useFetchUploadingOk();
  const uploadingDisabledNotification = useUploadingDisabledNotification();
  const handleUploadClick = () => {
    if (!isUploadingAllowed) {
      uploadingDisabledNotification();

      return;
    }

    setIsDialogOpen(true);
  };

  const ButtonContent = isMobile ? <PlusIcon sx={{ mt: 0.5 }} /> : <>{t('upload', { ns: NAMESPACE_COMMON })}</>;

  const handlerType = isPortfolio
    ? {
        onClick: handleUploadClick,
      }
    : {
        href: ccWebUploadUrl,
      };

  return (
    <>
      {isLoading ? (
        <UploadButtonSkeleton />
      ) : (
        <Button
          sx={isMobile ? mobileUploadButtonStyle : null}
          data-testid={getTestIds(isMobile)}
          {...handlerType}
          trackAction={CommonTrackActions.upload}
        >
          <>{ButtonContent}</>
        </Button>
      )}

      <UploaderDialog isOpen={isDialogOpen} closeDialog={() => setIsDialogOpen(false)} />
    </>
  );
};
