import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'next-i18next';

import { getEarningsHeaderUrl } from '@/apiRoutes/earnings';
import { Link } from '@/components/Link';
import { UploadButton } from '@/components/UploadButton/UploadButton';
import { SiteHeaderPageSections } from '@/constants/analytics';
import { SITE_ROUTES } from '@/constants/global';
import { useCurrencyFormatter } from '@/hooks/formatters/useCurrencyFormatter';
import { useFetch } from '@/hooks/useFetch';
import { PageSection } from '@/lib/analytics/react-data-tracking';
import { NAMESPACE_COMMON } from '@/translations/namespaces';
import { useFormatLink } from '@/utils/url';

import { SiteHeaderLogo } from './SiteHeaderLogo';
import { SiteHeaderMobileMenu } from './SiteHeaderMobileMenu';
import { SiteHeaderUserIcon } from './SiteHeaderUserIcon';
import { classes } from './styles';

export const SiteHeader = () => {
  const currencyFormatter = useCurrencyFormatter();
  const { data } = useFetch(getEarningsHeaderUrl().formattedUrl);
  const unpaidEarnings = data?.unpaid?.total || 0;
  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { t } = useTranslation(NAMESPACE_COMMON);
  const earningsLink = useFormatLink({
    href: SITE_ROUTES.EARNINGS_PAGE,
  });

  return (
    <PageSection value={SiteHeaderPageSections.siteHeader}>
      <AppBar
        id="SiteHeader"
        position="relative"
        color="transparent"
        elevation={0}
        sx={classes.appBar}
        data-testid="site_header"
      >
        <Grid
          data-testid="AppBarGrid"
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={classes.container}
          wrap="nowrap"
          spacing={0}
        >
          <Grid item xs="auto">
            <SiteHeaderLogo />
          </Grid>
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item sx={classes.unpaidEarningsWrapper} data-testid="unpaidEarnings">
                {unpaidEarnings > 0 && (
                  <Link href={earningsLink} sx={classes.earningsLink}>
                    <Typography variant="bodyStaticSm" sx={classes.siteHeaderEarnings}>
                      {t('contributor_header_unpaid_earnings', { amount: currencyFormatter(unpaidEarnings) })}
                    </Typography>
                  </Link>
                )}
              </Grid>

              <Grid item>
                <UploadButton />
              </Grid>
              {isMobileView ? (
                <Grid item data-testid="MobileMenu">
                  <SiteHeaderMobileMenu />
                </Grid>
              ) : (
                <Grid item data-testid="UserIcon">
                  <SiteHeaderUserIcon />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </AppBar>
    </PageSection>
  );
};
