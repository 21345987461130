import ListItemButtonUntracked, { ListItemButtonProps } from '@mui/material/ListItemButton';
import { ForwardRefExoticComponent, RefAttributes } from 'react';

import { withClickTracking } from '@/lib/analytics/react-data-tracking';

type TrackedListItemButton = ForwardRefExoticComponent<
  ListItemButtonProps & RefAttributes<HTMLAnchorElement> & { trackAction?: string }
>;

export const ListItemButton = withClickTracking(ListItemButtonUntracked) as TrackedListItemButton;
