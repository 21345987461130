import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';

import { getPublicProfile } from '@/apiRoutes/publicProfile';
import { useSubMenuDrawer } from '@/components/SiteMenu/SubMenuDrawer/hooks';
import { UserDetailsDrawer } from '@/components/UserDetailsDrawer';
import { IconButton } from '@/components/WithTracking/IconButton';
import { CommonTrackActions, SiteHeaderPageSections } from '@/constants/analytics';
import { ICON_SIZE } from '@/constants/icons';
import { useFetch } from '@/hooks/useFetch';
import { useFetchUser } from '@/hooks/useFetchUser';
import { PersonOutlineIcon } from '@/icons/PersonOutlineIcon';
import { PageSection } from '@/lib/analytics/react-data-tracking';
import { createProfileCdnUrl } from '@/utils/url';

const createStyles = ({ spacing }) => ({
  userIconWrapper: {
    paddingRight: { sm: spacing('base'), xs: spacing('base') },
  },
  userIcon: {
    backgroundColor: 'rgb(0,0,0,0.1)',
    '&:hover': {
      background: 'none',
      backgroundColor: 'rgb(0,0,0,0.1)',
    },
    height: ICON_SIZE.STANDARD,
    width: ICON_SIZE.STANDARD,
  },
});

export const SiteHeaderUserIcon = () => {
  const classes = createStyles(useTheme());
  const { closeDrawer } = useSubMenuDrawer();
  const [isUserDetailsDrawerOpen, setIsUserDetailsDrawerOpen] = useState(false);

  const { user } = useFetchUser();
  const account = user?.details?.account;
  const { data: userPublicProfileData } = useFetch(
    account?.contributorId ? getPublicProfile(account.contributorId).formattedUrl : null,
  );

  const hasProfileImage = userPublicProfileData?.publicInformation?.storageKey;
  const profileImageCdnPath = userPublicProfileData?.publicInformation?.cdnThumbPath;

  const toggleDrawer = () => {
    closeDrawer();
    setIsUserDetailsDrawerOpen(!isUserDetailsDrawerOpen);
  };

  return (
    <PageSection value={SiteHeaderPageSections.userIcon}>
      <Box sx={classes.userIconWrapper}>
        <IconButton style={classes.userIcon} onClick={toggleDrawer} trackAction={CommonTrackActions.toggle}>
          {profileImageCdnPath && hasProfileImage ? (
            <Avatar src={createProfileCdnUrl(profileImageCdnPath)} />
          ) : (
            <PersonOutlineIcon data-testid="PersonOutlineIcon" />
          )}
        </IconButton>
        <UserDetailsDrawer isActive={isUserDetailsDrawerOpen} toggleDrawer={toggleDrawer} />
      </Box>
    </PageSection>
  );
};
