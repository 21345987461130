import { getPublicSettings, getPublicSettingsForLegacy } from '@/apiRoutes/publicSettings';
import { useFetch } from '@/hooks/useFetch';
import { useFetchUser } from '@/hooks/useFetchUser';
import { useUserStore } from '@/stores/useUserStore';

export const useFetchUploadingOk = () => {
  const isLoggedIn = useUserStore((state) => state.isLoggedIn);
  const { user, isLoading: isUserLoading } = useFetchUser();
  const account = user?.details?.account;
  let publicSettingsUrl;

  if (account?.id) {
    if (isLoggedIn && account?.contributorId === account?.id) {
      publicSettingsUrl = getPublicSettings(account?.contributorId).formattedUrl;
    } else {
      publicSettingsUrl = getPublicSettingsForLegacy(
        account?.contributorId,
        account?.id,
        account?.username,
      ).formattedUrl;
    }
  } else {
    publicSettingsUrl = null;
  }

  const { data, isLoading: isSettingsLoading } = useFetch(publicSettingsUrl);
  const uploadingOk = data?.uploadingOk ?? true;

  return { uploadingOk, isLoading: isUserLoading || isSettingsLoading };
};
