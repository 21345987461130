import type Analytics from '@shutterstock-private/analytics-wrapper';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { getAnalyticsInstance, initializeAnalytics } from '@/lib/analytics';

import { useVisitorInfo } from './useVisitorInfo';

const { publicRuntimeConfig } = getConfig();

const analyticsWrapper = getAnalyticsInstance(publicRuntimeConfig);

export const useAnalyticalWrapper = (user: object | undefined, region: string = '', locale: string = '') => {
  const router = useRouter();
  const { visitorId, visitId, geoLocationCountryCode } = useVisitorInfo(region);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    initializeAnalytics(analyticsWrapper, publicRuntimeConfig, router, user, {
      visitorId,
      visitId,
      geoLocationCountryCode,
      locale,
    });
  }, [router, user, visitorId, visitId, geoLocationCountryCode, locale]);

  return analyticsWrapper as Analytics;
};
