export const PAGES_API_BASE_URL = '/api/next';

// CSV-Errors
export const MEDIA_CSV_ROW_ERRORS = '/media/csv/row_errors';
export const MEDIA_CSV_UPLOAD = '/media/csv/upload';
export const MEDIA_UPLOADS_CSV_UPLOAD_API = '/v1/media/manifest';

// Notification
export const MEDIA_UPLOAD_NOTIFICATIONS = '/media/upload/notifications';
export const MEDIA_UPLOAD_DISMISS_NOTIFICATIONS = '/media/upload/notifications/bulk/delete';

// Location
export const LOCATIONS = '/location/search';
export const LOCATIONS_DETAILS = '/location/details';

// Apollo Graph
export const NEXT_APOLLO_GRAPH = '/data-catalog';

// Analytics
export const NEXT_ANALYTICS = '/analytics';
export const ANALYTICS = '/cubejs-api/graphql';
// Payouts
export const PAYMENTS_HISTORY_URL = '/payouts/history';
export const EARNINGS_HEADER_URL = '/v2/earnings/header';

// Earnings
export const EARNINGS_RECENT = '/earnings/recent';

// EarningsV2
export const EARNINGS_REFERRALS_CUSTOMERS_URL = '/v2/earnings/referrals/customer';
export const EARNINGS_REFERRALS_CONTRIBUTORS_URL = '/v2/earnings/referrals/contributor';
export const EARNINGS_HIGHLIGHTS_URL = '/v2/earnings/summary';
export const EARNINGS_MEDIA_SUMMARY_URL = '/v2/earnings/media_summary';
export const EARNINGS_OVERVIEW_URL = '/v2/earnings/aggregate';
export const EARNINGS_BY_MONTH_URL = '/v2/earnings/by_month';
export const REFERRALS_SUMMARY_URL = '/v2/earnings/referrals/summary';
export const EARNINGS_BY_DATE = 'v2/earnings/media_stats/day';
export const EARNINGS_ADJUSTMENTS = '/v2/earnings/adjustments';
export const EARNINGS_GRAPH = '/v2/earnings/graph';
export const EARNINGS_AGGREGATE_ALLTIME = '/v2/earnings/aggregate/alltime';
export const EARNINGS_LEVELS = '/v2/earnings/levels';
export const EARNINGS_GALLERY = '/v2/earnings/gallery';
export const EARNINGS_TOP_PERFORMERS = '/v2/earnings/top';

// User
export const CURRENT_USER_URL = '/users/current';
export const ACCOUNT_SETTINGS_URL = '/users/current/account_settings';
export const PUBLIC_USERS_SETTINGS_URL = '/public/users/settings';
export const ACCOUNT_ADDRESS_URL = 'users/current/address';
export const ACCOUNT_PAYONEER_URL = 'payouts/signup_url/payoneer';
export const USER_PROFILE_URL = '/users/current/profile';
export const USER_PROFILE_PICTURE_URL = '/users/current/profile/photo';
export const USER_EXPERIMENTS = '/user/experiments';

// Public
export const PUBLIC_USERS_URL = '/public/users';

// Taxes
export const TREATY_COUNTRIES = '/v1/legal/taxes/treaty_countries';
export const PAYEE_USER_INFO = '/v1/payees/user-';
export const PAYEE_SUBMIT_FORM = '/v1/payees/forms';
export const PAYEE_CURRENT_FORM = '/v1/payees/current';
export const TAX_SURVEY_RESPONSE = '/v1/payees/current/tax_survey_responses';

// cc-api taxes
export const EXTERNAL_TAX_FORMS = '/taxes/current/external';
export const RETRIEVE_TAX_FORM = '/taxes/external';

// Collection
export const COLLECTION = '/collection';
export const COLLECTION_LIST = '/collection/list'; // Collection V2
export const COLLECTION_ITEMS = '/collection/items';
export const COLLECTION_PUBLIC_CONTRIBUTOR_API = '/sets/public';
export const COLLECTION_PUBLIC = '/collection/public';
export const COLLECTION_EARNINGS = '/sets/{id}/earnings';

// Collection V3
export const COLLECTIONS_V3 = '/collections';
export const COLLECTION_LIST_V3 = '/collection/listV3';
export const COLLECTION_BY_ID = '/collections/{collectionId}';
export const NEXT_COLLECTION_BY_ID = '/collection/{collectionId}';
export const COLLECTIONS_TOP_PERFORMERS = '/collection/top-performers';

export const PAYEE_SUBMIT_SIGNED_FORM = '/v1/payees/current/forms/sign';

// Contentful
export const CONTENTFUL_IMAGE_TYPE_ENTRIES_URL = '/image/content-types';
export const CONTENTFUL_ENTRIES_URL = '/entries';
export const CONTENTFUL_URL = '/contentful';

// Media
export const MEDIA_V1_PHOTOS = '/library/shutterstock/photo'; // Gives you a set of brief metadata for the photo ids you specify. This operation should be considered less expensive than individual operations.
export const MEDIA_V1_VIDEOS = '/library/shutterstock/video'; // Gives you a set of brief metadata for the video ids you specify. This operation should be considered less expensive than individual operations.
export const MEDIA_PHOTO_INTERNAL = '/media/photo';
export const MEDIA_VIDEO_INTERNAL = '/media/video';
export const MEDIA_SUGGESTIONS_INTERNAL = '/media/suggestions';
export const MEDIA_V1_PHOTO_PATCH = '/library/shutterstock/photo/{id}';
export const MEDIA_V1_VIDEO_PATCH = '/library/shutterstock/video/{id}';
export const SEARCH_CC_API = '/media/search/{namespace}/{mediaType}/query';
export const SUMMARY_MEDIA = '/summary/media';
export const MEDIA_SEARCH = '/media/search';
export const MEDIA_SUGGESTIONS = '/media/{id}/suggestions';
export const BULK_DELETE_MEDIA = '/media/bulk/delete';
export const BULK_DELETE_MEDIA_INTERNAL = '/media/bulkDelete';
export const MEDIA_META_CATEGORIES = '/media/categories';

// Media CC-API
export const MEDIA = '/media';
export const MEDIA_REVIEW = '/media/review';
export const MEDIA_LIST = '/media/list';
export const MEDIA_REVIEW_LIST = '/media/review/list';
export const MEDIA_BY_ID = '/media/{id}';
export const MEDIA_SUBMIT = '/media/submit';
export const MEDIA_REJECTION_REASONS = '/media/review/meta/reasons/{locale}';
export const MEDIA_REJECTION_REASONS_INTERNAL = '/media/review/rejection';
export const MEDIA_BULK_UPDATE = '/media/bulk/update';

// Validate media
export const VALIDATE_MEDIA_INTERNAL = '/media/validate';
export const VALIDATE_MEDIA = '/validate/media';

// Sets
export const SETS = '/sets';
export const SET_BY_ID = '/sets/{id}';
export const SET_ITEMS = '/sets/{id}/items';
export const SET_EARNINGS = '/sets/{id}/earnings';

// Catalog
export const CATALOG_COUNT_ALL_URL = '/catalog/count_all';

// Releases
export const RELEASES = '/media/releases';
export const RELEASES_BY_ID = '/media/releases/{releaseId}';
export const RELEASE_MODEL_SIGN = '/media/releases/digital/model/sign';
export const RELEASE_MINOR_MODEL_SIGN = '/media/releases/digital/minor_model/sign';
export const RELEASE_PROPERTY_OWNER_SIGN = '/media/releases/digital/property_owner/sign';

// Media Uploads
export const MEDIA_UPLOADS_RELEASE = '/media/releases/asset';
export const MEDIA_UPLOADS_RELEASE_V1_API = '/v1/release/asset';
export const MEDIA_UPLOADS_ASSET_INTERNAL = '/media/initiateUpload';
export const MEDIA_UPLOADS_ASSET = '/v2/initiate-upload';

// Messages
export const MESSAGES_URL = '/messages';

// Settings
export const SYSTEM_SETTINGS_URL = '/users/shutterstock-contributor-app/application/settings/system';
export const SETTINGS_INTERNAL_URL = '/settings-service';

// Contributor accounts
export const LOGIN_URL = '/login';

// Contributor rampup
export const RAMP_UP = '/rampup_multi';

// Licensing
export const LICENSING_CURRENT_VERSION = '/licenses/{license_provider}';

// Contact us
export const CONTACT_US = '/support/contact';
export const CONTACT_US_INTERNAL = '/contact';
