import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const DataCatalogIcon = (props: Partial<SvgIconProps>) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 5.975H18V3.975H2V5.975ZM2 17.975H18V9.975H2V17.975ZM0 19.975H20V7.975H0V19.975ZM4 2H16V0H4V2Z"
      fill="#0C121C"
      fillOpacity="0.87"
    />
  </SvgIcon>
);
