/**
 * Will convert a string from camel case to Snake case.
 * @param {string} str
 * @returns
 */
export const camelToSnakeCase = (str) => str.replace(/[A-Z0-9]/g, (letter) => `_${letter.toLowerCase()}`);

/**
 * Will convert keys of an object to snake case.
 * Note this is shallow.
 * @param {Object}} data
 * @returns
 */
export const objectToSnakeCase = (data) =>
  Object.keys(data).reduce((acc, curr) => ({ ...acc, ...{ [camelToSnakeCase(curr)]: data[curr] } }), {});

/**
 * Will capitalize first letter.
 * @param {string} str
 * @returns
 */
export const capitalizeCase = ([first, ...rest]) => first.toUpperCase() + rest.join('');
