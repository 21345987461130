import axios from 'axios';
import camelcase from 'camelcase-keys';
import getConfig from 'next/config';
import { v4 as uuidv4 } from 'uuid';

import { PAGES_API_BASE_URL } from '@/constants/api';
import { ACCEPT, AUTHORIZATION, CONTENT_TYPE, END_APP_NAME, END_APP_VERSION, REQUEST_ID } from '@/constants/headers';
import { formatAuthToken } from '@/middleware/authentication';

const {
  publicRuntimeConfig: { _app, endpoints },
} = getConfig();

// Add default app headers on every request
axios.defaults.headers.common[ACCEPT] = 'application/json';
axios.defaults.headers.common[END_APP_NAME] = _app.name;
axios.defaults.headers.common[END_APP_VERSION] = _app.version;

/**
 * gets the base url for a given API Name based on the current environment.
 * @param {String} apiName
 * @return {String} base url
 */
const getBaseUrl = (apiName) => {
  const endpoint = endpoints[apiName];

  // Checks if the code is running server/client side
  return typeof window === 'undefined' ? endpoint : PAGES_API_BASE_URL;
};

/**
 * axios API instance.
 * Use for internal API calls.
 * @example
 * // GET /api/hello
 * fetch.get('hello')
 * @type {import('axios').AxiosInstance}
 */
const fetch = axios.create({
  baseURL: getBaseUrl(),
});

/**
 * axios Contributor API instance.
 * Use for Contributor API calls.
 * @example
 * // GET https://contributor-carbon-api.shuttercorp.net/public/users/{contributor_id}/profile
 * fetchContributorApi.get('public/users/{contributor_id}/profile')
 * @type {import('axios').AxiosInstance}
 */
const fetchContributorApi = axios.create({
  baseURL: getBaseUrl('contributor'),
});

/**
 * axios Contributor Tax API instance.
 * Use for Tax API calls.
 * @example
 * // GET http://taxes-api.shuttercorp.net/v1/legal/taxes/treaty_countries
 * fetchTaxesApi.get('v1/legal/taxes/treaty_countries')
 * @type {import('axios').AxiosInstance}
 */
const fetchTaxesApi = axios.create({
  baseURL: getBaseUrl('taxes'),
});

/**
 * axios Analytics API instance.
 * Use for analytics API calls.
 * @type {import('axios').AxiosInstance}
 */
const fetchAnalyticsApi = axios.create({
  baseURL: getBaseUrl('apollo'),
});

/**
 * axios Collection instance.
 * Use for Collection API calls.
 * @type {import('axios').AxiosInstance}
 */
const fetchCollectionApi = axios.create({
  baseURL: getBaseUrl('collection'),
});

/**
 * axios Contentful instance.
 * Use for Contentful API calls.
 * @type {import('axios').AxiosInstance}
 */
const fetchContentfulApi = axios.create({
  baseURL: getBaseUrl('contentful'),
});

/* axios MediaV1 instance.
 * Use for MediaV1 calls.
 * @type {import('axios').AxiosInstance}
 */
const fetchMediaV1Api = axios.create({
  baseURL: getBaseUrl('mediaV1'),
});

/* axios MediaUploadsV1 instance.
 * Use for MediaUploadsV1 calls.
 * @type {import('axios').AxiosInstance}
 */
const fetchMediaUploadsV1Api = axios.create({
  baseURL: getBaseUrl('mediaUploadV1'),
});

/* axios Settings instance.
 * Use for Settings calls.
 * @type {import('axios').AxiosInstance}
 */
const fetchSettingsApi = axios.create({
  baseURL: getBaseUrl('settings'),
});

/* axios contributor accounts instance.
 * Use for contributor accounts calls.
 * @type {import('axios').AxiosInstance}
 */
const fetchContributorAccountsApi = axios.create({
  baseURL: getBaseUrl('contributorAccounts'),
});

/**
 * @type {import('axios').AxiosInstance}
 */
const fetchStudioApi = axios.create({
  baseURL: getBaseUrl('studio'),
});

/**
 * @type {import('axios').AxiosInstance}
 */
const fetchLicensingApi = axios.create({
  baseURL: getBaseUrl('licensing'),
});

/**
 * format the response data object using camelCase format.
 * @param {import("axios").AxiosResponse} response
 * @return {import("axios").AxiosResponse} transformed response
 */
const camelizeResponse = (response) => {
  // to prevent camelcase call for i.e. pdf files
  // includes is used to cover content-type value like 'application/json; charset=utf-8'
  const isApplicationJson = response.headers[CONTENT_TYPE]?.includes('application/json');

  // On the server we will transform the data to camel case, which means
  // less work for the client to do.
  if (response.config.baseURL !== PAGES_API_BASE_URL && isApplicationJson) {
    response.data = camelcase(response.data, { deep: true });
  }

  return response;
};

/**
 * adding dynamic default request headers
 * @param {import("axios").AxiosRequestConfig} config
 * @return {import("axios").AxiosRequestConfig} transformed request config
 */
const defaultRequestInterceptor = (config) => {
  const { headers } = config;

  headers[REQUEST_ID] = config.requestId || headers[REQUEST_ID] || uuidv4();

  if (config.authorization) {
    headers[AUTHORIZATION] = formatAuthToken(config.authorization);
  }

  return config;
};

/**
 * Transforms Error object from Axios call
 * @param {import("axios").AxiosError} error
 * @return {import("axios").AxiosError} transformed error
 */
const transformError = (error) =>
  // Handles the Error object returned from Contributor API, not from the NextJS API endpoints.
  // error.config includes the users' authorization token, so it must be removed on the server-side.
  Promise.reject(Object.assign(error, { config: undefined }));

[
  // here all axios instances should be listed to make sure defaultRequestInterceptor is set up for them
  fetch,
  fetchContributorApi,
  fetchTaxesApi,
  fetchCollectionApi,
  fetchContentfulApi,
  fetchMediaV1Api,
  fetchMediaUploadsV1Api,
  fetchSettingsApi,
  fetchContributorAccountsApi,
  fetchAnalyticsApi,
  fetchLicensingApi,
].forEach((api) => {
  api.interceptors.request.use(defaultRequestInterceptor);
  api.interceptors.response.use(camelizeResponse, transformError);
});
/**
 * We don't want to camelize transform the response data for the Studio API so extracting from above array.
 */
fetchStudioApi.interceptors.request.use(defaultRequestInterceptor);

export {
  fetch,
  fetchContributorApi,
  fetchTaxesApi,
  fetchCollectionApi,
  fetchMediaV1Api,
  fetchMediaUploadsV1Api,
  fetchContentfulApi,
  fetchSettingsApi,
  fetchStudioApi,
  fetchContributorAccountsApi,
  fetchAnalyticsApi,
  fetchLicensingApi,
};
