import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const SaveToCollectionIcon = (props: Partial<SvgIconProps>) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 11H19V9H17V7H19V5H21V7H23V9H21V11ZM17 20H1V4H11V7H15V9H11C9.8 9 9 8.2 9 7V6H3V18H17C18.4 18 19 17.5 19 16V13H21V16C21 18.6 19.5 20 17 20V20Z"
    />
  </SvgIcon>
);
