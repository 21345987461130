import { breakpoints } from './breakpoints';
import { components } from './components';
import { spacing } from './spacing';
import * as tokens from './tokens';
import { typography } from './typography';

export const themeBase = {
  breakpoints,
  spacing,
  tokens,
  typography,
  components,
};
