// Based on the following file from next-web: https://github.shuttercorp.net/web/next-web/blob/main/src/middlewares/pathRewrites.js
import Cookies from 'js-cookie';

import { i18nSet } from '@/constants/i18n-locales';

import { setShutterstockCookies } from './shutterstockCookies';

const { stringify: queryStringify } = require('qs');

// remove '?language=' query parameter to prevent confusion for end-user, only path locale (e.g. '/fr') considered
export const removeLanguageSearchParameter = async (router) => {
  const { query, pathname } = router;

  if (!query.language) return;

  const queryString = queryStringify({ ...query, language: undefined });
  const updatedQuery = queryString.length === 0 ? '' : `?${queryString}`;

  router.push(pathname + updatedQuery, undefined, { shallow: true });
};

export const checkLocaleIsInURLPath = (langToCheck) => {
  const currentUrl = new URL(window.location.href);

  return !!currentUrl.pathname.match(new RegExp(`/${langToCheck}(/?$|/.*)`));
};

export const getFirstDefinedLocaleCookie = (localeCookiesNames) => {
  let cookieLocale;

  // There is no need to read all cookies, as we are only interested in finding the one with highest priority.
  // eslint-disable-next-line no-return-assign
  localeCookiesNames.find((cookieName) => (cookieLocale = Cookies.get(cookieName)));

  return cookieLocale;
};

export const getValidCookieLocale = (localeCookiesNames, router) => {
  const cookieLocale = getFirstDefinedLocaleCookie(localeCookiesNames);

  if (!cookieLocale || cookieLocale === router.locale) return cookieLocale;

  // e.g. if cookie locale is set as 'fr-FR', we need to match it with 'fr'. Also have to consider 'zh-Hant'.
  return i18nSet.has(cookieLocale)
    ? cookieLocale
    : router.locales.find((validLocale) => cookieLocale.match(new RegExp(`^${validLocale}`)));
};

export const syncLocaleCookies = ({ router, setToLocale }) => {
  removeLanguageSearchParameter(router);

  let localeCookiesNames = ['language', 'locale', 'hl']; // priority list

  // If the language is specified in the path, e.g. /fr/earnings, this gets highest priority.
  if (!setToLocale && checkLocaleIsInURLPath(router.locale)) {
    // can update cookies asynchronously, as no redirect required
    return new Promise((resolve) => {
      setShutterstockCookies({
        key: [...localeCookiesNames, 'NEXT_LOCALE'],
        value: router.locale,
        options: { expires: 365 },
      });

      resolve(router.locale);
    });
  }

  let foundLocale = setToLocale ?? getValidCookieLocale(localeCookiesNames, router);

  if (!foundLocale) {
    // if the other Shutterstock locale cookies are set to a value that neither Contributor
    // site currently support, then we will only update the Contributor-related cookies

    foundLocale = router.locale;
    localeCookiesNames = ['language'];
  }

  setShutterstockCookies({
    key: [...localeCookiesNames, 'NEXT_LOCALE'],
    value: foundLocale,
    options: { expires: 365 },
  });

  if (router.locale !== foundLocale || setToLocale) router.push(router.asPath, undefined, { locale: foundLocale });

  return Promise.resolve(foundLocale);
};
