import { PUBLIC_USERS_SETTINGS_URL } from '@/constants/api';

export const getPublicSettings = (id: string) => {
  const query = { accounts_id: id, fresh: '1' };

  return {
    url: PUBLIC_USERS_SETTINGS_URL,
    formattedUrl: `${PUBLIC_USERS_SETTINGS_URL}?${new URLSearchParams(query)}`,
  };
};

export const getPublicSettingsForLegacy = (accountId: string, globalId: string, username: string) => {
  const query = { accounts_id: globalId, contributor_id: accountId, display_name: username, fresh: '1' };

  return {
    url: PUBLIC_USERS_SETTINGS_URL,
    formattedUrl: `${PUBLIC_USERS_SETTINGS_URL}?${new URLSearchParams(query)}`,
  };
};
