import { useFilesHandler } from './useFilesHandler';

const MAX_FILE_COUNT = 100;

type Args = {
  successfulUploadHandler?: () => void;
  failedUploadHandler?: () => void;
};

export const useHandleUpload = ({ successfulUploadHandler, failedUploadHandler }: Args = {}) => {
  const uploadFiles = useFilesHandler();
  const handleOnChange = (fileList: any) => {
    const files = Array.from(fileList);

    if (files.length > MAX_FILE_COUNT || !files.length) {
      failedUploadHandler?.();

      return;
    }

    uploadFiles(files);
    successfulUploadHandler?.();
  };

  return { handleOnChange };
};
