import { create } from 'zustand';

type UploadStoreState = {
  isUploading: boolean;
  setIsUploading: (clickedUpload: boolean) => void;
};

export const useUploadStore = create<UploadStoreState>((set) => ({
  isUploading: false,
  setIsUploading: (isUploading) => set({ isUploading }),
}));
