import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const DataIcon = (props: Partial<SvgIconProps>) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 20.0003V1.00031H4V20.0003H22V22.0003H4H2V20.0003ZM5.2336 16.3617L6.7666 17.6387L11.3866 12.0947L16.4586 13.1097L20.8296 6.5537L19.1696 5.4467L15.5406 10.8907L10.6136 9.9057L5.2336 16.3617Z"
    />
  </SvgIcon>
);
