import { spacing } from '../spacing';
import { color } from '../tokens';
import { typography } from '../typography';

export const MuiTooltipOverrides = {
  defaultProps: {
    arrow: true,
    placement: 'top',
  },
  styleOverrides: {
    tooltip: {
      fontSize: typography.bodyStaticSm.fontSize,
      backgroundColor: color.gray['onyx-87'],
      padding: spacing('base'),
      a: {
        color: color.blue.aqua,
      },
    },
    arrow: {
      height: spacing('base'),
      width: spacing('m'),
      color: color.gray['onyx-87'],
      '.MuiTooltip-popper[data-popper-placement*="top"] &': {
        marginBottom: `-${spacing('base')}`,
      },
      '.MuiTooltip-popper[data-popper-placement*="bottom"] &': {
        top: `-7px !important`,
      },
      '&:before': {
        borderRadius: 4,
      },
    },
  },
};
