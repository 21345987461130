import { Trans } from 'next-i18next';

import { Link } from '@/components/Link';
import { EMAIL } from '@/constants/contactInfo';
import { useNotifications } from '@/hooks/useNotifications';
import { NAMESPACE_COMMON } from '@/translations/namespaces';

export function useUploadingDisabledNotification() {
  const { displayInfo } = useNotifications();

  return () => {
    displayInfo?.(
      (
        <Trans
          ns={NAMESPACE_COMMON}
          i18nKey="uploading_has_been_disabled"
          values={{ name: EMAIL }}
          components={{
            b: <b />,
            a: <Link sx={{ color: 'link.contrastText', textDecoration: 'underline' }} href={`mailto:${EMAIL}`} />,
          }}
        />
      ) as unknown as string,
    );
  };
}
