import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import propTypes from 'prop-types';

const HelpfulTipBlock = ({ icon, title, description }) => (
  <Grid
    container
    direction={{ md: 'column', lg: 'row' }}
    justifyContent="center"
    alignItems="center"
    columnSpacing={1.3}
    maxWidth="40%"
  >
    <Grid item xs={1}>
      {icon}
    </Grid>
    <Grid item xs={10}>
      <Box pb={1} textAlign="center">
        <Typography variant="bodyStaticXs" fontWeight="bold" color="text.secondary" mb={0.5} maxWidth="80%">
          {title}
        </Typography>
        <Typography variant="bodyStaticXs" color="text.secondary" maxWidth="80%">
          {description}
        </Typography>
      </Box>
    </Grid>
  </Grid>
);

HelpfulTipBlock.propTypes = {
  icon: propTypes.element.isRequired,
  title: propTypes.string.isRequired,
  description: propTypes.element.isRequired,
};

export { HelpfulTipBlock };
