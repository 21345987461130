import { fetchContributorApi } from '@/lib/fetch';
import { withAuth } from '@/middleware/authentication';

export class ContributorApi {
  constructor(req, res) {
    this.req = req;
    this.res = res;
  }

  async getConfig() {
    return {
      authorization: await withAuth(this.req, this.res),
      requestId: this.req.id,
    };
  }

  async get(url, customConfig) {
    return fetchContributorApi.get(url, customConfig || (await this.getConfig()));
  }

  async put(url, data, customConfig) {
    return fetchContributorApi.put(url, data, customConfig || (await this.getConfig()));
  }

  async patch(url, body, customConfig) {
    const config = customConfig || (await this.getConfig());

    return fetchContributorApi.patch(url, body, config);
  }

  async post(url, body, customConfig) {
    const config = customConfig || (await this.getConfig());

    return fetchContributorApi.post(url, body, config);
  }

  async delete(url, customConfig) {
    const config = customConfig || (await this.getConfig());

    return fetchContributorApi.delete(url, config);
  }
}
