import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const HistoryIcon = (props: Partial<SvgIconProps>) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.52351 3.13142C8.92351 2.37442 10.4925 1.98542 12.0835 2.00042C16.9385 2.17742 20.9635 5.81742 21.6265 10.6304C22.3795 16.1024 18.5545 21.1474 13.0835 21.9004V19.9004C16.8865 19.3054 19.7265 16.0844 19.8415 12.2354C19.9735 7.81942 16.4995 4.13242 12.0835 4.00042C10.8765 3.98942 9.68451 4.26442 8.60351 4.80142L9.18351 5.70042L6.38351 5.10142L6.98351 2.30142L7.52351 3.13142ZM5.60009 6.76702L4.20009 5.36702C2.92809 6.85902 2.15809 8.71302 2.00009 10.667H4.00009C4.19809 9.25302 4.74809 7.91302 5.60009 6.76702ZM13.0835 11.6674V5.66742H11.0835V10.8564L7.38351 14.4674L8.88351 15.8664L13.0835 11.6674ZM4 12.6674H2C2.133 14.5304 2.831 16.3104 4 17.7674L5.4 16.3664C4.587 15.2944 4.101 14.0094 4 12.6674ZM11.0835 21.9004C9.0445 21.6924 7.1225 20.8534 5.5835 19.5004L6.9835 18.1014C8.1285 19.1134 9.5625 19.7434 11.0835 19.9004V21.9004Z"
    />
  </SvgIcon>
);
