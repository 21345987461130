import { createContext, useState } from 'react';

type PageFormState = {
  pageHasDirtyForm: boolean;
  pageHasModalOpen: boolean;
  setPageHasDirtyForm: (arg: boolean) => void;
  setPageHasModalOpen: (arg: boolean) => void;
};

export const PageFormStateContext = createContext<PageFormState>({
  pageHasDirtyForm: false,
  pageHasModalOpen: false,
  setPageHasDirtyForm: () => {},
  setPageHasModalOpen: () => {},
});

type PageFormStateProvider = {
  children: React.ReactNode;
};

export const PageFormStateProvider = ({ children }: PageFormStateProvider) => {
  const [pageHasDirtyForm, setPageHasDirtyForm] = useState(false);
  const [pageHasModalOpen, setPageHasModalOpen] = useState(false);

  return (
    <PageFormStateContext.Provider
      value={{ pageHasDirtyForm, setPageHasDirtyForm, pageHasModalOpen, setPageHasModalOpen }}
    >
      {children}
    </PageFormStateContext.Provider>
  );
};
