import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { SITE_HEADER_HEIGHT } from '@/constants/global';

import { DESKTOP_SITE_MENU_SECTIONS } from './constants';
import { MenuItem } from './MenuItem';
import { SubMenuDrawer } from './SubMenuDrawer';

const createStyles = ({ spacing, palette }) => ({
  siteMenuDesktop: {
    display: { xs: 'none', md: 'flex' },
    flexDirection: 'column',
    backgroundColor: palette.white.main,
    justifyContent: 'space-between',
    minHeight: `calc(100% - ${SITE_HEADER_HEIGHT})`, // SiteHeader-height == 56px
    flexShrink: 0,
    width: spacing('xxl'),
    borderRight: `calc(${spacing('xxs')} / 2) solid ${palette.border2Color}`,
    zIndex: 2,
    position: 'sticky',
    top: 0,
  },
  desktopUpperSiteMenuItems: {
    position: 'sticky',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: spacing('base'),
  },
  desktopLowerSiteMenuItems: {
    position: 'sticky',
    bottom: '0px',
    width: '100%',
    display: 'flex',
    padding: `calc(${spacing('base')} - ${spacing('xs')}) 0px`,
    flexDirection: 'column',
    alignItems: 'center',
    borderTop: `calc(${spacing('xxs')} / 2) solid ${palette.border2Color}`,
    backgroundColor: `${palette.background.secondary}4C`, // 30% opacity
  },
});

export const SiteMenu = () => {
  const classes = createStyles(useTheme());

  return (
    <Box id="SiteMenu" sx={classes.siteMenuDesktop}>
      <SubMenuDrawer />
      <div style={classes.desktopUpperSiteMenuItems}>
        {DESKTOP_SITE_MENU_SECTIONS.UPPER.map((itemName) => (
          <MenuItem key={itemName} iconType={itemName} />
        ))}
      </div>
      <div style={classes.desktopLowerSiteMenuItems}>
        {DESKTOP_SITE_MENU_SECTIONS.LOWER.map((itemName) => (
          <MenuItem key={itemName} iconType={itemName} />
        ))}
      </div>
    </Box>
  );
};
