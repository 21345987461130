import MUIIconButton, { IconButtonProps } from '@mui/material/IconButton';
import { FC } from 'react';

import { withClickTracking } from '@/lib/analytics/react-data-tracking';

interface ExtendedIconButtonProps extends IconButtonProps {
  trackAction: string;
}

const UntrackedIconButton: FC<ExtendedIconButtonProps> = function UntrackedIconButton(props) {
  return <MUIIconButton {...props} />;
};

export const IconButton = withClickTracking(UntrackedIconButton);
