import SvgIcon from '@mui/material/SvgIcon';

import { IconType } from './IconType';

export const LanguageIcon = ({ sx, ...props }: IconType) => (
  <SvgIcon sx={sx} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.36 14C16.44 13.34 16.5 12.68 16.5 12C16.5 11.32 16.44 10.66 16.36 10H19.74C19.9 10.64 20 11.31 20 12C20 12.69 19.9 13.36 19.74 14H16.36ZM14.589 19.56C15.19 18.45 15.65 17.25 15.969 16H18.92C17.96 17.65 16.429 18.93 14.589 19.56V19.56ZM14.339 14H9.66C9.57 13.34 9.5 12.68 9.5 12C9.5 11.32 9.57 10.65 9.66 10H14.339C14.429 10.65 14.5 11.32 14.5 12C14.5 12.68 14.429 13.34 14.339 14V14ZM12 19.96C11.17 18.76 10.519 17.43 10.089 16H13.91C13.48 17.43 12.83 18.76 12 19.96V19.96ZM8.03 8H5.08C6.04 6.34 7.57 5.07 9.41 4.44C8.809 5.55 8.349 6.75 8.03 8V8ZM5.08 16H8.03C8.349 17.25 8.809 18.45 9.41 19.56C7.57 18.93 6.04 17.66 5.08 16V16ZM4.259 14C4.099 13.36 4 12.69 4 12C4 11.31 4.099 10.64 4.259 10H7.639C7.559 10.66 7.5 11.32 7.5 12C7.5 12.68 7.559 13.34 7.639 14H4.259ZM12 4.04C12.83 5.24 13.48 6.57 13.91 8H10.089C10.519 6.57 11.17 5.24 12 4.04V4.04ZM18.92 8H15.969C15.65 6.75 15.19 5.55 14.589 4.44C16.429 5.07 17.96 6.35 18.92 8V8ZM11.99 2C6.469 2 2 6.48 2 12C2 17.52 6.469 22 11.99 22C17.519 22 22 17.52 22 12C22 6.48 17.519 2 11.99 2V2Z"
    />
  </SvgIcon>
);
