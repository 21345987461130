import { ENABLED } from './constants';

const initMocks = async () => {
  if (typeof window === 'undefined' && process.env.NEXT_PUBLIC_API_MOCKING_SERVER === ENABLED) {
    const { server } = await import('./server');

    server.listen({ onUnhandledRequest: 'bypass' });
  } else if (process.env.NEXT_PUBLIC_API_MOCKING_BROWSER === ENABLED) {
    const { worker } = await import('./browser');

    await worker.start({ onUnhandledRequest: 'bypass', serviceWorker: { url: '/mockServiceWorker.js' } });
  }
};

const setupMockServer = async () => {
  if (process.env.NEXT_PUBLIC_API_MOCKING === ENABLED) {
    await initMocks();
  }
};

export { setupMockServer };
