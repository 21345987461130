import { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';
import useResizeObserver from 'use-resize-observer';

import { noop } from '@/utils/noop';

type BannerContextContextType = {
  enabled: boolean;
  desktopMinHeight: number;
  mobileMinHeight: number;
  updateContainerRef: (element: HTMLElement | null) => void;
};

const BannerContext = createContext<BannerContextContextType>({
  enabled: false,
  desktopMinHeight: 0,
  mobileMinHeight: 0,
  updateContainerRef: noop,
});

type BannerProviderProps = {
  children: React.ReactNode;
  enabled?: boolean;
};

export const useBannerContext = () => {
  const context = useContext(BannerContext);

  if (!context) {
    throw new Error('useBannerContext must be used within a BannerProvider');
  }

  return context;
};

export const BannerProvider = ({ children, enabled }: BannerProviderProps) => {
  const [desktopMinHeight, setDesktopMinHeight] = useState<number>(0);
  const [mobileMinHeight, setMobileMinHeight] = useState<number>(0);
  const containerRef = useRef<HTMLElement | null>(null);
  const { height = 0 } = useResizeObserver<HTMLElement>({ ref: containerRef });

  const setBannerMinHeights = useCallback(() => {
    if (desktopMinHeight !== height) {
      setDesktopMinHeight(height);
    }

    if (mobileMinHeight !== height) {
      setMobileMinHeight(height);
    }
  }, [setDesktopMinHeight, setMobileMinHeight, desktopMinHeight, mobileMinHeight, height]);

  const updateContainerRef = useCallback((element: HTMLElement | null) => {
    if (element) {
      containerRef.current = element;
    }
  }, []);

  setBannerMinHeights();

  const value = useMemo(
    () => ({
      enabled: !!enabled,
      desktopMinHeight,
      mobileMinHeight,
      updateContainerRef,
    }),
    [desktopMinHeight, enabled, mobileMinHeight, updateContainerRef],
  );

  return <BannerContext.Provider value={value}>{children}</BannerContext.Provider>;
};
