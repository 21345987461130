import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ArrowRightIcon = (props: Partial<SvgIconProps>) => (
  <SvgIcon viewBox="-3 -2 20 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 7.508L12.271 7.478L8.136 11.613L9.508 12.985L16 6.492L9.508 0L8.136 1.372L12.301 5.537L0 5.508V7.508Z"
    />
  </SvgIcon>
);
