import { LINK_TYPE, NON_C2N_ROUTES, SITE_ROUTES } from '@/constants/global';

const linkData = {
  'Contributor Blog': {
    clickAnalyticsLabel: 'siteFooter.contributorBlog',
    label: 'Contributor Blog',
    linkTranslationKey: 'contributor_footer_contributor_blog',
    linkType: LINK_TYPE.CONTRIBUTOR_BLOG,
    target: '_blank',
  },
  'Contributor Mobile App': {
    clickAnalyticsLabel: 'siteFooter.MobileApp',
    label: 'Contributor Mobile App',
    linkTranslationKey: 'contributor_footer_contributor_mobile_app',
    href: NON_C2N_ROUTES.MOBILE_APP,
    linkType: LINK_TYPE.SHUTTERSTOCK_NEXT_PUBLIC,
    target: '_blank',
  },
  'Earnings Breakdown': {
    clickAnalyticsLabel: 'siteFooter.earningsBreakdown',
    label: 'Earnings Breakdown',
    linkTranslationKey: 'contributor_footer_earnings_breakdown',
    href: SITE_ROUTES.PAYOUTS_PAGE,
  },
  'Submission Guidelines': {
    clickAnalyticsLabel: 'siteFooter.submissionGuidelines',
    label: 'Submission Guidelines',
    linkTranslationKey: 'contributor_footer_submission_guidelines',
    href: `/contributorsupport/redirect?a=000006656`,
    linkType: LINK_TYPE.SHUTTERSTOCK_REDIRECT,
    target: '_blank',
  },
  'Contact Us': {
    clickAnalyticsLabel: 'siteFooter.contactUs',
    label: 'Contact Us',
    linkTranslationKey: 'contributor_footer_contact_us',
    href: SITE_ROUTES.CONTACT_PAGE,
  },
  'Contributor Support Center': {
    clickAnalyticsLabel: 'siteFooter.contributorSupportCenter',
    label: 'Contributor Support Center',
    linkTranslationKey: 'contributor_footer_support_center',
    href: NON_C2N_ROUTES.CONTRIBUTOR_SUPPORT,
    linkType: LINK_TYPE.SHUTTERSTOCK_REDIRECT,
    target: '_blank',
  },
  'Tax Center': {
    clickAnalyticsLabel: 'siteFooter.taxCenter',
    label: 'Tax Center',
    linkTranslationKey: 'contributor_legal_center_tax_center',
    href: SITE_ROUTES.TAX_CENTER,
    linkType: LINK_TYPE.CCWEB_REDIRECT,
  },
  'Model & Property Releases': {
    clickAnalyticsLabel: 'siteFooter.Model&PropertyReleases',
    label: 'Model & Property Releases',
    linkTranslationKey: 'contributor_footer_model_and_property_releases',
    href: NON_C2N_ROUTES.LEGAL_PAGE,
    linkType: LINK_TYPE.CCWEB_REDIRECT,
  },
  'Privacy Policy': {
    clickAnalyticsLabel: 'siteFooter.privacyPolicy',
    label: 'Privacy Policy',
    linkTranslationKey: 'contributor_footer_privacy_policy',
    href: NON_C2N_ROUTES.PRIVACY,
    linkType: LINK_TYPE.SHUTTERSTOCK_NEXT_PUBLIC,
    target: '_blank',
  },
  'Terms of Service': {
    clickAnalyticsLabel: 'siteFooter.termsOfService',
    label: 'Terms of Service',
    linkTranslationKey: 'contributor_footer_terms_of_service',
    href: NON_C2N_ROUTES.TERMS_OF_SERVICE,
    linkType: LINK_TYPE.CCWEB_REDIRECT,
  },
  'Editorial Content Supply Agreement': {
    clickAnalyticsLabel: 'siteFooter.editorialContentSupplyAgreement',
    label: 'Editorial Content Supply Agreement',
    linkTranslationKey: 'contributor_footer_editorial_legal',
    href: 'https://ak.picdn.net/assets/cms/d27bfc3b4fe133f0cc762d8e23e0091758a2d12f-Editorial%20A.pdf',
    target: '_blank',
  },
  'Support Center': {
    clickAnalyticsLabel: 'siteFooter.supportCenter',
    label: 'contributor_footer_support_center',
    linkTranslationKey: 'contributor_footer_support_center',
    href: '/',
    linkType: LINK_TYPE.CONTRIBUTOR_SUPPORT_CENTER,
  },
  'License Agreement': {
    clickAnalyticsLabel: 'siteFooter.licenseAgreement',
    label: 'contributor_footer_license_agreement',
    linkTranslationKey: 'contributor_footer_license_agreement',
    href: '/license',
    linkType: LINK_TYPE.SHUTTERSTOCK_NEXT_PUBLIC,
  },
};

export const footerData = [
  {
    label: 'Shutterstock Contributor',
    headerTranslationKey: 'shutterstock_contributor',
    links: [
      linkData['Contributor Blog'],
      linkData['Contributor Mobile App'],
      linkData['Earnings Breakdown'],
      linkData['Submission Guidelines'],
    ],
  },
  {
    label: 'Help',
    headerTranslationKey: 'help',
    links: [linkData['Contact Us'], linkData['Contributor Support Center']],
  },
  {
    label: 'Legal & Compliance',
    headerTranslationKey: 'legal_and_compliacne',
    links: [
      linkData['Tax Center'],
      linkData['Model & Property Releases'],
      linkData['Privacy Policy'],
      linkData['Terms of Service'],
      linkData['Editorial Content Supply Agreement'],
    ],
  },
];

export const signUpFooterData = [
  linkData['Support Center'],
  linkData['Contact Us'],
  linkData['Privacy Policy'],
  linkData['License Agreement'],
];
