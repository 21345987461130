import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const AccountSettingsIcon = (props: Partial<SvgIconProps>) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 19L20 12L16 5H8L4 12L8 19H16ZM7 21L2 12L7 3H17L22 12L17 21H7ZM10 12C10 13.104 10.896 14 12 14C13.104 14 14 13.104 14 12C14 10.896 13.104 10 12 10C10.896 10 10 10.896 10 12ZM8 12C8 9.791 9.791 8 12 8C14.209 8 16 9.791 16 12C16 14.209 14.209 16 12 16C9.791 16 8 14.209 8 12Z"
    />
  </SvgIcon>
);
