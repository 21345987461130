const defaultVariantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'h6',
  subtitle2: 'h6',
  body1: 'p',
  body2: 'p',
  bodyStaticLg: 'h2',
  bodyStaticMd: 'h6',
  bodyStaticSm: 'p',
  bodyStaticXs: 'p',
  bodyBoldSm: 'p',
};

export const MuiTypographyOverrides = {
  defaultProps: {
    variants: [
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'subtitle1',
      'subtitle2',
      'body1',
      'body2',
      'caption',
      'button',
      'overline',
      'srOnly',
      'inherit',
      'bodyStaticLg',
      'bodyStaticMd',
      'bodyStaticSm',
      'bodyStaticXs',
      'bodyBoldSm',
    ],
    variantMapping: defaultVariantMapping,
  },
};
