/*
Replaces `lodash.throttle` with fewer features. 
At the time of this writing, no consumers of `lodash.throttle` in SSTK were using the `options` argument, 
which is why this version doesn't support that feature.
Ensures a function is called no more than once every `timeout` ms. 
Fires the function once at the beginning of the timeout, then, if the function was called during the timeout,
fires it again at the end of the timeout. 
The original function's return value is discarded.
Examples:
const f = throttle(() => console.log('fn called'), 500);
f(); 
-- console: 'fn called'
... wait 500ms
f(); f(); f();
-- console: 'fn called'
... wait 500ms
-- console: 'fn called'
*/
export const throttle = (fn, timeout = 0) => {
  let timeoutId = null;
  let lastArgs = null;

  return function throttledFn(...args) {
    if (!timeoutId) {
      fn(...args);
      timeoutId = setTimeout(() => {
        timeoutId = null;

        if (lastArgs) {
          const argsToPass = lastArgs;

          lastArgs = null;
          throttledFn(...argsToPass);
        }
      }, timeout);
    } else {
      lastArgs = args;
    }
  };
};
