import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Slide from '@mui/material/Slide';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createContext, forwardRef, memo, useCallback, useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { Backdrop } from '@/components/Dialog/Backdrop';
import { DISABLE_CLICK_AWAY_CLASS } from '@/constants/global';

export const ConfirmationContext = createContext(null);
export const ModalContext = createContext(null);

const contentWrapperStyle = ({ spacing, breakpoints }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 440,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: spacing(5),
  borderRadius: '16px',
  outline: 'none',
  display: 'flex',
  flexDirection: 'column',
  [breakpoints.down('sm')]: {
    minWidth: '90%',
    height: '100%',
    overflowY: 'auto',
  },
});

const createStyles = ({ breakpoints, palette, spacing, tokens: { font } }) => ({
  '.MuiDialogTitle-root': {
    pt: spacing('xl'),
    position: 'relative',
    [breakpoints.down('sm')]: {
      pt: spacing(10),
      lineHeight: font.lineHeight.micro,
    },

    '.CloseButton': {
      position: 'absolute',
      right: spacing('m'),
      top: spacing('l'),
      color: palette.text.secondary,
    },
  },
});

const PortalConfirmationId = 'confirmationRoot';
const PortalModalId = 'modalRoot';

const Anchor = ({ portalId, Context }) => {
  const { content } = useContext(Context);
  const [anchor, setAnchor] = useState(null);

  useEffect(() => {
    if (document.getElementById(portalId)) {
      setAnchor(ReactDOM.createPortal(content, document.getElementById(portalId)));
    } else {
      setAnchor(null);
    }
  }, [content, portalId]);

  return anchor;
};
const ConfirmationAnchor = () => <Anchor portalId={PortalConfirmationId} Context={ConfirmationContext} />;

const ModalAnchor = () => <Anchor portalId={PortalModalId} Context={ModalContext} />;

const SlideUp = forwardRef(function SlideUp(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const FadeIn = forwardRef(function FadeIn(props, ref) {
  return <Fade ref={ref} {...props} />;
});

const MobileTransition = memo(SlideUp);
const DesktopTransition = memo(FadeIn);

export const ModalProvider = ({ children }) => {
  const [confirmationContent, setConfirmationContent] = useState();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationOptions, setConfirmationOptions] = useState({});
  const [modalContent, setModalContent] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOptions, setModalOptions] = useState({});
  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const { sx: sxModal, ...modalProps } = modalOptions.modalProps || {};
  const { sx: sxPaper, ...paperProps } = modalOptions.paperProps || {};
  const [modalContainerStyles, setModalContainerStyles] = useState({});
  const [anchorModalContainerStyles, setAnchorModalContainerStyles] = useState({});

  useEffect(() => {
    if (!modalOpen && modalOptions.onClose) {
      modalOptions.onClose();
    }
  }, [modalOpen, modalOptions]);

  const openConfirmation = useCallback(
    (content, options) => {
      setConfirmationContent(content);
      setConfirmationOpen(true);
      setConfirmationOptions(options ?? {});
    },
    [setConfirmationContent, setConfirmationOpen],
  );

  const closeConfirmation = useCallback(() => {
    setConfirmationOpen(false);
    setConfirmationContent(null);
  }, [setConfirmationOpen, setConfirmationContent]);

  const openModal = useCallback(
    (content, options) => {
      setModalContent(content);
      setModalOpen(true);
      setModalOptions(options ?? {});
    },
    [setModalOptions, setModalContent, setModalOpen],
  );

  const closeModal = useCallback(() => {
    setModalOpen(false);
    setModalContent(null);
  }, [setModalOpen, setModalContent]);

  return (
    <ConfirmationContext.Provider
      value={{ openConfirmation, closeConfirmation, content: confirmationContent, ConfirmationAnchor }}
    >
      <ModalContext.Provider
        value={{
          openModal,
          closeModal,
          content: modalContent,
          ModalAnchor,
          setModalContainerStyles,
          setAnchorModalContainerStyles,
        }}
      >
        <>{children}</>

        <Dialog
          open={modalOpen && !!modalContent}
          fullScreen={isMobile}
          onClose={closeModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          TransitionComponent={isMobile ? MobileTransition : DesktopTransition}
          scroll="paper"
          sx={[createStyles, sxModal]}
          maxWidth={modalOptions.maxWidth ?? 'md'}
          fullWidth
          keepMounted
          slots={{ backdrop: Backdrop }}
          {...modalProps}
          PaperProps={{
            sx: [{ alignItems: 'stretch', justifyContent: 'flex-start' }, sxPaper, modalContainerStyles],
            ...paperProps,
          }}
        >
          {modalOptions.withAnchor ? (
            <Box sx={[{ display: 'flex', flexDirection: 'column' }, anchorModalContainerStyles]} id={PortalModalId} />
          ) : (
            modalContent
          )}
        </Dialog>
        <Modal
          open={confirmationOpen && !!confirmationContent}
          keepMounted
          onClose={closeConfirmation}
          aria-labelledby="confirmation-title"
          aria-describedby="confirmation-description"
          data-testid="open-confirmation"
          slots={{ backdrop: Backdrop }}
          slotProps={{
            root: { className: confirmationOptions?.disableClickAwayHandler ? DISABLE_CLICK_AWAY_CLASS : '' },
          }}
        >
          <Box sx={contentWrapperStyle}>
            {confirmationOptions.withAnchor ? <Box id={PortalConfirmationId} /> : confirmationContent}
          </Box>
        </Modal>
      </ModalContext.Provider>
    </ConfirmationContext.Provider>
  );
};
