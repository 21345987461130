const i18n = {
  defaultLocale: 'en',
  locales: [
    'cs',
    'da',
    'de',
    'en',
    'es',
    'fi',
    'fr',
    'hu',
    'it',
    'ja',
    'ko',
    'nb',
    'nl',
    'pl',
    'pt',
    'ru',
    'sv',
    'th',
    'tr',
    'zh-Hant',
    'zh',
  ],
};

const i18nSet = new Set(i18n.locales);

module.exports = {
  i18n,
  i18nSet,
};
