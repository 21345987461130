import { LinkProps } from '@mui/material/Link';
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';

import { Link as UntrackedLink } from '@/components/Link';
import { usePageFormState } from '@/hooks/usePageFormState';
import { useUnsavedDialog } from '@/hooks/useUnsavedDialog';
import { withClickTracking } from '@/lib/analytics/react-data-tracking';

type TrackedLink = ForwardRefExoticComponent<LinkProps & RefAttributes<HTMLAnchorElement> & { trackAction?: string }>;

const withUnsavedDialog = <T extends object>(Component: React.FC<T>) => {
  const NewComponent = forwardRef((props: T, ref) => {
    const { pageHasDirtyForm } = usePageFormState() || { pageHasDirtyForm: false };
    const { showCustomUnsavedDialog } = useUnsavedDialog();
    const { onClick: originalOnClick, href, ...otherProps } = props as any;
    const isExternalLink = /^(https?:\/\/)|\/\//.test(href);
    const showCustomUnsavedModal = !isExternalLink && pageHasDirtyForm;

    const onTrigger = (e: MouseEvent & KeyboardEvent) => {
      if (showCustomUnsavedModal) {
        if (e.key === 'Enter' || e.type === 'click') {
          e.preventDefault();
          showCustomUnsavedDialog(href);
        }
      }

      if (originalOnClick) {
        return originalOnClick(e);
      }

      return null;
    };

    return <Component ref={ref} {...otherProps} onClick={onTrigger} onKeyDown={onTrigger} href={href} />;
  });

  const name = Component.displayName || Component.name;

  NewComponent.displayName = `unsavedDialog(${name})`;

  return NewComponent;
};

export const Link = withUnsavedDialog(withClickTracking(UntrackedLink)) as TrackedLink;
