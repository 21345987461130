import Cookies from 'js-cookie';

import { useRegionContext } from '@/providers/Region/RegionProvider';

export const useVisitorInfo = (region?: string) => {
  const visitorRegion = useRegionContext() && region;
  const visitorId = Cookies.get('sstk_anonymous_id');
  const visitId = Cookies.get('sstk_session_id');

  return {
    visitorId,
    visitId,
    geoLocationCountryCode: visitorRegion,
  };
};
