import type Analytics from '@shutterstock-private/analytics-wrapper';
import AnalyticsWrapper from '@shutterstock-private/analytics-wrapper';
import { createContext, useContext } from 'react';

const AnalyticsWrapperContext = createContext<Analytics | null>(null);

interface ProviderProps {
  opts?: Record<string, unknown>;
  children: JSX.Element;
  awInstance?: AnalyticsWrapper;
}

export const DataTrackingProvider = (props: ProviderProps) => {
  const { awInstance, children } = props;
  const analyticsWrapperInstance = awInstance || new AnalyticsWrapper(props?.opts);

  return (
    <AnalyticsWrapperContext.Provider value={analyticsWrapperInstance}>{children}</AnalyticsWrapperContext.Provider>
  );
};

export const useDataTracking = () => useContext(AnalyticsWrapperContext);
