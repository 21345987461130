import { ROUTE_TO_ICON_MAPPING, ROUTE_TO_PARENT_ICON_MAPPING, SUBMENU_ITEMS } from './constants';

export const itemHasSubMenu = (menuItemName) => !!SUBMENU_ITEMS[menuItemName];

export const itemShouldHighlight = (iconType, asPath) => {
  const path = `/${asPath.split('/')[1]}`;

  return (
    ROUTE_TO_ICON_MAPPING[asPath] === iconType ||
    ROUTE_TO_PARENT_ICON_MAPPING[asPath] === iconType ||
    ROUTE_TO_ICON_MAPPING[path] === iconType ||
    ROUTE_TO_PARENT_ICON_MAPPING[path] === iconType
  );
};
