import { ICON_SIZE } from '@/constants/icons';

export const createSocialMediaStyles = ({ spacing }, options = {}) => ({
  display: 'grid',
  gap: spacing('base'),
  gridAutoFlow: 'column',
  justifyContent: options.justifyContent || 'start',
  '& .MuiSvgIcon-root': {
    color: options.color || 'background.contrast',
    fontSize: ICON_SIZE[options.size?.toUpperCase()] || ICON_SIZE.MEDIUM,
    textDecoration: 'none',
    '&:hover': {
      color: options.hoverColor || options.color || 'background.contrast',
    },
  },
});
