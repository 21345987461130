import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const LinkedinIcon = (props: Partial<SvgIconProps>) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.188 18.189H16.373V13.781C16.373 12.729 16.353 11.377 14.909 11.377C13.442 11.377 13.218 12.522 13.218 13.705V18.189H10.404V9.123H13.105V10.361H13.143C13.519 9.648 14.439 8.897 15.81 8.897C18.662 8.897 19.188 10.774 19.188 13.216V18.189ZM7.226 7.883C6.321 7.883 5.591 7.151 5.591 6.249C5.591 5.348 6.321 4.615 7.226 4.615C8.127 4.615 8.86 5.348 8.86 6.249C8.86 7.151 8.127 7.883 7.226 7.883V7.883ZM5.815 18.189H8.635V9.123H5.815V18.189ZM20.592 2H4.401C3.627 2 3 2.613 3 3.369V19.628C3 20.385 3.627 21 4.401 21H20.592C21.367 21 22 20.385 22 19.628V3.369C22 2.613 21.367 2 20.592 2V2Z"
    />
  </SvgIcon>
);
