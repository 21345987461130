const SUPPORTED_ENVS = ['dev', 'production', 'qa'];

// In experiment-constants ALLOW_LIST_CLIENT_IDS
// using values for 'mobileIosContributor' for now, need to update key to `contributor-web` in SDK...
// https://github.shuttercorp.net/experimentation/experiments-sdk-js/blob/main/packages/experiments-service/src/constants/constants.ts#L67
const ENV_CLIENT_ID_MAP = {
  dev: 'c8cd459b2548727398c2',
  qa: 'fc77a0990e3a90b852b9',
  production: '13b6a7181b2cdc67fa1d',
};

type SupportedEnv = 'dev' | 'production' | 'qa';

// Expects to take clientID from an environment variable, so handles undefined & string[] cases
export const getClientId = (env: string | undefined | string[]) => {
  if (typeof env === 'string' && SUPPORTED_ENVS.includes(env)) {
    return ENV_CLIENT_ID_MAP[env as SupportedEnv];
  }

  return ENV_CLIENT_ID_MAP.qa;
};
