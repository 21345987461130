import { useEffect } from 'react';
import type { Metric, MetricWithAttribution } from 'web-vitals';
import { onINP, onTTFB } from 'web-vitals/attribution';

type ReportDataTypeBase = {
  initialPage: string;
  // metricName: Metric['name'];
  metricName: 'TTFB' | 'INP';
  value: Metric['value'];
  metricId: Metric['id'];
  navigationType: Metric['navigationType'];
  delta: Metric['delta'];
  href: string;
  speed: unknown;
  entries?: string;
  attribution?: string;
};

type TTFBAttributionData = {
  metricName: 'TTFB';
  waitingDuration: number;
  dnsDuration: number;
  connectionDuration: number;
  cacheDuration: number;
  requestDuration: number;
  navigationEntry?: PerformanceNavigationTiming;
};

type INPAttributionData = {
  metricName: 'INP';
  interactionTargetElementOuterHTML?: string;
  nextPaintTime: number;
  interactionTime: number;
  interactionTarget: string;
  interactionType: string;
  loadState: string;
  inputDelay: number;
  processingTime?: number;
  presentationDelay: number;
  metricEventTarget?: string;
  metricEventType?: string;
  metricEventTime?: number;
  metricLoadState?: string;
};

type ReportDataTypeINP = ReportDataTypeBase & Partial<INPAttributionData>;
type ReportDataTypeTTFB = ReportDataTypeBase & Partial<TTFBAttributionData>;
type ReportDataType = ReportDataTypeINP | ReportDataTypeTTFB;
const reportWebVitals = (metric: Metric | MetricWithAttribution) => {
  if (metric.name !== 'TTFB' && metric.name !== 'INP') {
    return;
  }

  const data: ReportDataType = {
    // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-explicit-any
    initialPage: (window as any).__NEXT_DATA__?.page,
    metricName: metric.name,
    value: metric.value,
    metricId: metric.id,
    navigationType: metric.navigationType,
    delta: metric.delta,
    href: window.location.href,
    speed:
      // @ts-expect-error - TS2339: Property 'connection' does not exist on type 'Navigator'.
      'connection' in navigator && 'effectiveType' in navigator.connection ? navigator.connection.effectiveType : '',
  };

  if (data.metricName === 'TTFB' && metric.name === 'TTFB' && 'attribution' in metric) {
    data.waitingDuration = metric.attribution?.waitingDuration;
    data.dnsDuration = metric.attribution?.dnsDuration;
    data.connectionDuration = metric.attribution?.connectionDuration;
    data.cacheDuration = metric.attribution?.cacheDuration;
    data.requestDuration = metric.attribution?.requestDuration;
  }

  if (data.metricName === 'INP' && metric.name === 'INP' && 'attribution' in metric) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data.interactionTargetElementOuterHTML = (metric.attribution.interactionTargetElement as any)?.outerHTML as string;
    data.nextPaintTime = metric.attribution?.nextPaintTime;
    data.metricEventTime = metric.attribution?.interactionTime as number;
    data.metricEventTarget = metric?.attribution?.interactionTarget as string;
    data.metricEventType = metric?.attribution?.interactionType as string;
    data.metricLoadState = metric?.attribution?.loadState as string;
    data.inputDelay = metric?.attribution?.inputDelay;
    data.processingTime = metric?.attribution?.processingDuration;
    data.presentationDelay = metric?.attribution?.presentationDelay;
  }

  window?.NREUM?.addPageAction('web-vitals', data);
};

export function useReportWebVitals() {
  useEffect(() => {
    // only report INP and TTFB as new relic gets all the other metrics for us
    // new relic gets INP but this is a custom implementation to get the attribution data and force reporting all changes
    // in an effort to get more data and less 'null' values for event target.
    onINP(reportWebVitals, { reportAllChanges: true });
    onTTFB(reportWebVitals);
    // onCLS(reportWebVitals);
    // onFCP(reportWebVitals);
    // onFID(reportWebVitals);
    // onLCP(reportWebVitals);
  }, []);
}
