// commonjs exports, used by client snd server
const path = require('path');

// Should only be include client side, ensure to check on this before adding to config.use
const backend = require('i18next-http-backend/cjs');

const { i18n } = require('./src/constants/i18n-locales');

module.exports = {
  defaultNS: 'common',
  fallbackLng: false, // prevent loading en language chunks for other languages
  i18n,
  interpolation: {
    // DEFAULT ones provided from https://github.com/isaachinman/next-i18next/blob/01069e39a1c55e5df88de852c65198eb611abc09/src/config/default-config.ts#L21-L23
    escapeValue: false,
    formatSeparator: ',',
    format: (value, format) => (format === 'uppercase' ? value.toUpperCase() : value),
    prefix: '__',
    suffix: '__',
  },
  react: {
    useSuspense: false, // Suspense not supported server side
  },
  partialBundledLanguages: true, // Important, without this client side fetching of translations will not work.
  use: process.browser ? [backend] : [],
  localeStructure: '__lng__/__ns__',
  serializeConfig: false,
  localePath: path.resolve('./public/static/locales'),
  nsSeparator: '::',
  shallowRender: false,
};
