import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import { SnackbarProvider } from 'notistack';

const TransitionDown = (props) => <Slide {...props} direction="down" />;

export const NotificationsProvider = ({ children }) => (
  <Box
    sx={({ spacing }) => ({
      '.SnackbarContainer-root': {
        mt: spacing('l'),
      },
    })}
  >
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      dense
      TransitionComponent={TransitionDown}
    >
      {children}
    </SnackbarProvider>
  </Box>
);
