import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const HelpIcon = (props: Partial<SvgIconProps>) => (
  <SvgIcon width="20" height="20" viewBox="-2 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 18C5.589 18 2 14.411 2 10C2 5.589 5.589 2 10 2C14.411 2 18 5.589 18 10C18 14.411 14.411 18 10 18ZM10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0ZM9 16H11V14H9V16ZM10 4C7.794 4 6 5.794 6 8H8C8 6.897 8.897 6 10 6C11.103 6 12 6.897 12 8C12 9.103 11.103 10 10 10H9V13H11V11.874C12.724 11.428 14 9.86 14 8C14 5.794 12.206 4 10 4Z"
    />
  </SvgIcon>
);
