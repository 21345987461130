import { createContext, useContext } from 'react';

export const RegionContext = createContext('US');
export const useRegionContext = () => useContext(RegionContext);

type RegionProviderProps = {
  region: string;
  children: React.ReactNode;
};

export const RegionProvider = ({ region, children }: RegionProviderProps) => (
  <RegionContext.Provider value={region}>{children}</RegionContext.Provider>
);
