import { useEffect, useMemo, useRef, useState } from 'react';

import { useAssetUploadedAnalyticsEvent } from '@/hooks/analytics/useAssetUploadedAnalyticsEvent';
import { useFetchUser } from '@/hooks/useFetchUser';
import { useUploadStore } from '@/stores/useUploadStore';
import { noop } from '@/utils/noop';
import { throttle } from '@/utils/throttle';

import { UploadStates, WidgetStates } from './constants';
import { UploadsContext } from './UploadsContext';

type UploadsProviderProps = {
  children: React.ReactNode;
};

export type UploadItem = {
  cancel: () => void;
  startUpload: () => void;
  state: string;
  file: File;
  loaded: number;
  failedReason: string;
  postUploadCallback: (uploadItem: UploadItem) => Promise<void>;
};

export const UploadsProvider = ({ children }: UploadsProviderProps) => {
  const uploadList = useRef<UploadItem[]>([]);
  // translated warning, for browsers that supports it, will be injected by useUploadsContext
  const warningText = useRef('Uploads in progress, closing the page will terminate your uploads');
  const mutationHandler = useRef(noop);
  const [lastOperation, setLastOperation] = useState(Date.now());
  const [widgetState, setWidgetState] = useState(WidgetStates.Closed);
  const assetUploadedAnalyticsEvent = useAssetUploadedAnalyticsEvent();
  const setIsUploading = useUploadStore((state) => state.setIsUploading);

  useEffect(() => {
    setIsUploading(widgetState === WidgetStates.Open);
  }, [setIsUploading, widgetState]);

  const isLoading = useRef(false);
  const loadingPromise = useRef(Promise.resolve());
  const { isOffsetUser } = useFetchUser();
  const uploadHandlers = useRef({
    enqueueUpload: (uploadObj: any) => {
      if (!isLoading.current) {
        isLoading.current = true;
        loadingPromise.current = import('./uploadHandlers').then(({ uploadHandlersGen }) => {
          uploadHandlers.current = uploadHandlersGen(
            uploadList,
            throttle(setLastOperation, 500),
            isOffsetUser,
            assetUploadedAnalyticsEvent,
          );
        });
      }

      loadingPromise.current = loadingPromise.current.then(() => {
        uploadHandlers.current.enqueueUpload(uploadObj);
      });
    },
    enqueueFailedUpload: (uploadObj: any) => {
      if (!isLoading.current) {
        isLoading.current = true;
        loadingPromise.current = import('./uploadHandlers').then(({ uploadHandlersGen }) => {
          uploadHandlers.current = uploadHandlersGen(uploadList, throttle(setLastOperation, 500), isOffsetUser);
        });
      }

      loadingPromise.current = loadingPromise.current.then(() => {
        uploadHandlers.current.enqueueFailedUpload(uploadObj);
      });
    },
    cancelAllUploads: noop,
    clearList: noop,
  });

  const progressInfo = uploadList.current.reduce(
    (acc, uploadItem) => {
      if (uploadItem.state !== UploadStates.Errored) {
        acc.totalSize += uploadItem.file.size;
      }

      if (uploadItem.state === UploadStates.Done) {
        acc.uploadedSize += uploadItem.file.size;
      } else if (uploadItem.loaded && uploadItem.state !== UploadStates.Errored) {
        acc.uploadedSize += uploadItem.loaded;
      }

      return acc;
    },
    { totalSize: 0, uploadedSize: 0 },
  );

  const uploadsInProgress = uploadList.current.filter(
    (uploadItem) => uploadItem.state === UploadStates.Uploading || uploadItem.state === UploadStates.Queued,
  ) as never[];
  const currentlyInProgress = uploadsInProgress.length > 0;

  if (widgetState === WidgetStates.Closed && uploadList.current.length) {
    setWidgetState(WidgetStates.Open);
  }

  const uploadsFinished =
    uploadList.current.length > 0 &&
    uploadList.current.every(
      (uploadItem) =>
        uploadItem.state === UploadStates.Done ||
        uploadItem.state === UploadStates.Errored ||
        uploadItem.state === UploadStates.Canceled,
    );

  if (uploadsFinished) {
    mutationHandler.current();
  }

  useEffect(() => {
    if (currentlyInProgress) {
      const handleWindowClose = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = warningText.current;

        return warningText.current;
      };

      window.addEventListener('beforeunload', handleWindowClose);

      return () => {
        window.removeEventListener('beforeunload', handleWindowClose);
      };
    }

    return noop;
  }, [currentlyInProgress]);

  const providerValue = useMemo(
    () => ({
      ...uploadHandlers.current,
      uploadList: uploadList.current as never[],
      widgetState,
      setWidgetState,
      uploadsInProgress,
      mutationHandler,
      warningText,
      progress: progressInfo.totalSize ? progressInfo.uploadedSize / progressInfo.totalSize : 0,
    }),
    // We don't want to memoize on the upload list or its elements that are all REFs.
    // we take lastOperation as a way to break the memoization of the context.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastOperation, widgetState, setWidgetState, progressInfo.uploadedSize, progressInfo.totalSize, uploadsInProgress],
  );

  return <UploadsContext.Provider value={providerValue}>{children}</UploadsContext.Provider>;
};
