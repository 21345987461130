export const classes = {
  appBar: {
    borderBottom: 'solid',
    borderBottomWidth: 'thin',
    borderBottomColor: ({ palette }) => palette.border2Color,
    height: ({ spacing }) => `calc(${spacing('xl')} + ${spacing('s')})`, // 56px
    boxShadow: 'none',
    minWidth: 'max-content',
  },
  unpaidEarningsWrapper: {
    display: { md: 'inline', sm: 'none', xs: 'none' },
  },
  container: {
    height: ({ spacing }) => `calc(${spacing('xl')} + ${spacing('s')})`, // 56px
    minWidth: ({ spacing }) => `calc(2*${spacing('4xl')} + ${spacing('s')})`, // 280px, Stops header from minimising infinitely
  },
  siteHeaderEarnings: {
    color: ({ palette }) => palette.darkGrey.main,
    paddingTop: ({ spacing }) => spacing('xs'),
    width: 'max-content',
    textTransform: 'uppercase',
  },
  earningsLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
};
