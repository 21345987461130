import MUIButton, { buttonClasses } from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import { emptyArray } from '@/utils/noop';

const variantMap = {
  primary: { color: 'secondary', variant: 'contained' },
  secondary: { color: 'primary', variant: 'outlined' },
  secondaryInverse: { color: 'white', variant: 'outlined' },
  link: { color: 'link', variant: 'text' },
  linkInverse: { color: 'white', variant: 'text' },
  text: { color: 'none', variant: '' },
};

const variantDisabledMap = {
  link: {
    color: 'buttonOverrides.linkDisabledText',
  },
};

const getStyle =
  (sx, variant) =>
  ({ spacing }) => ({
    margin: spacing(0.5),
    [`&.${buttonClasses.disabled}`]: {
      ...variantDisabledMap[variant],
    },
  });

export const ButtonWithoutTracking = forwardRef(function Button(
  { disabled, sx = emptyArray, children, variant = 'primary', submit = false, sxTypography = emptyArray, ...restProps },
  ref,
) {
  return (
    <MUIButton
      data-testid="button"
      ref={ref}
      disabled={disabled}
      {...variantMap[variant]}
      {...(submit ? { type: 'submit', ...restProps } : { ...restProps })}
      sx={[getStyle(sx, variant), ...(Array.isArray(sx) ? sx : [sx])]}
    >
      <Typography
        sx={[...(Array.isArray(sxTypography) ? sxTypography : [sxTypography])]}
        variant={variant === 'link' ? 'bodyStaticSm' : 'bodyBoldSm'}
      >
        {children}
      </Typography>
    </MUIButton>
  );
});

ButtonWithoutTracking.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'secondaryInverse', 'link', 'linkInverse', 'text']),
  submit: PropTypes.bool,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
  sxTypography: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
};
