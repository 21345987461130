import { spacing as spacingTokens } from './tokens';

const FACTOR = 8;
/*  https://github.shuttercorp.net/studio/sstk/blob/main/apps/design-tokens/src/tokens/base/spacing.json
 *  {
 *    xxs: 2px,
 *    xs: 4px,
 *    s: 8px,
 *    base: 16px, // also known as default
 *    m: 24px,
 *    l: 36px,
 *    xl: 48px,
 *    xxl: 72px,
 *    3xl: 96px,
 *    4xl: 136px,
 *  }
 */

export const spacing = (size) => spacingTokens[size] || size * FACTOR;
