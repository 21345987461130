import { animation } from '@sstk/design-tokens/base/animation';
import { border } from '@sstk/design-tokens/base/border';
import { color } from '@sstk/design-tokens/base/color';
import { font as sstkFont } from '@sstk/design-tokens/base/font';
import { media } from '@sstk/design-tokens/base/media';
import { opacity } from '@sstk/design-tokens/base/opacity';
import { shadow } from '@sstk/design-tokens/base/shadow';
import { size } from '@sstk/design-tokens/base/size';
import { spacing } from '@sstk/design-tokens/base/spacing';
import * as zIndexTokens from '@sstk/design-tokens/base/z-index';

// FontWeight, lineHeight & fontSize token values taken from https://www.figma.com/file/CjU5ZhHTQpAQTLFUD7f5pN/AE-Component-Library-%E2%9C%94%EF%B8%8F?node-id=0%3A3272

const fontWeight = {
  normal: 400,
  bold: 700,
};

const lineHeight = {
  // h1: 68px
  h1: 4.533,
  // h2: 51px
  h2: 3.4,
  // h3: 32px
  h3: 2.133,
  // h4: 25px
  h4: 1.667,
  // large: 28px
  large: 1.867,
  // regular: 23px
  regular: 1.533,
  // small: 20px
  small: 1.333,
  // micro: 17px
  micro: 1.133,
  // Button
  lButton: '28px',
  mButton: '24px',
  sButton: '20px',
};

const fontSize = {
  // h1: 60px
  h1: sstkFont.size['5xl'],
  // h2: 46px
  h2: sstkFont.size['4xl'],
  // h3: 29px
  h3: sstkFont.size['3xl'],
  // h4: 23px
  h4: sstkFont.size.xxl,
  // large: 19px
  large: sstkFont.size.xl,
  l: sstkFont.size.xl,
  // regular: 15px
  regular: sstkFont.size.m,
  m: sstkFont.size.m,
  // small: 13px
  small: sstkFont.size.s,
  s: sstkFont.size.s,
  // micro: 11px
  micro: sstkFont.size.xs,
  xs: sstkFont.size.xs,
  // Button
  // large
  lButton: '18px',
  mButton: '16px',
  sButton: '14px',
  xsButton: '12px',
};

const font = {
  fontFamily: sstkFont.family.primary,
  weight: { ...fontWeight },
  lineHeight: { ...lineHeight },
  size: { ...fontSize },
  baseFontSize: sstkFont.body.primary,
};

const zIndex = zIndexTokens['z-index'];
const { radius } = border;

export { animation, border, color, font, media, opacity, shadow, size, spacing, zIndex, radius };
