import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

/**
 * Temporary hook to enable/disable the new portfolio experience
 * Logic works by which cookie is set, if portfolio_legacy_exp is set, it will always use the legacy experience while if portfolio_next_exp and portfolio_legacy_exp are not set, it will use the new experience.
 */
export const usePortfolioNextExp = function usePortfolioNextExp() {
  const [isNextExp, setIsNextExp] = useState<boolean>(false);
  const [forceLegacyExp, setForceLegacyExp] = useState<boolean>(false);

  useEffect(() => {
    setIsNextExp(Cookies.get('portfolio_next_exp') === 'true');
    setForceLegacyExp(Cookies.get('portfolio_legacy_exp') === 'true');
  }, []);

  if (forceLegacyExp) {
    return false;
  }

  return isNextExp;
};
