import Auth from '@shutterstock/auth';
import type { Request, Response } from 'express';
import getConfig from 'next/config';
import { promisify } from 'util';

const UNAUTHORIZED_HTTP_STATUS_CODE = 401;

const { publicRuntimeConfig: CONFIG } = getConfig();

export const getServiceAccessToken = (req: Request, processAuthResponse: (err: unknown, accessToken: string) => any) =>
  Auth(CONFIG.auth.NAMESPACE).getAccessToken(req, undefined, processAuthResponse);

export const formatAuthToken = (authToken: string) =>
  authToken.startsWith('Bearer') ? authToken : `Bearer ${authToken}`;

export const getAuthData = (req: Request) => Auth(CONFIG.auth.NAMESPACE).authData(req);

export const getAccessToken = (req: Request) => promisify(getServiceAccessToken)(req);

export const destroySession = (req: Request, res: Response) => Auth(CONFIG.auth.NAMESPACE).destroy(req, res, () => {});

export const withAuth = (
  req: Request,
  res: Response,
  { allowUnauthenticated = false, allowAuthBypass = res?.locals?.allowAuthBypass } = {},
) =>
  new Promise((resolve) => {
    getServiceAccessToken(req, (err, accessToken: string) => {
      if (err || !accessToken) {
        if (allowUnauthenticated || allowAuthBypass) {
          resolve(null);

          return;
        }

        const hasOnlyAuthState =
          req.session?.OAuthData?.authState && Object.keys(req.session?.OAuthData ?? {}).length === 1;

        const sessionFieldsForLogs = {
          hasOnlyAuthState,
          lastModified: req.session?.lastModified,
          OAuthData: {
            customer: {
              accessTokenPresent: !!req.session?.OAuthData?.customer?.accessToken,
              refreshTokenPresent: !!req.session?.OAuthData?.customer?.refreshToken,
              userTokenPresent: !!req.session?.OAuthData?.customer?.userToken,
              user: req.session?.OAuthData?.customer?.user,
              expiresAt: req.session?.OAuthData?.customer?.expiresAt,
              lastAuthorizedAt: req.session?.OAuthData?.customer?.lastAuthorizedAt,
            },
          },
          impersonatorId: req.session?.OAuthData?.customer?.impersonator_id,
        };

        if (sessionFieldsForLogs.impersonatorId && (err as { name: string })?.name === 'RedirectError') {
          // Accounts was not able to get an access token, maybe a race condition in session reads
          // let this request die if it needs one, ignore it otherwise
          req?.log?.error(
            {
              err,
              cookies: req.cookies,
              accessToken,
              url: req.url,
            },
            'CONTRIB-4136 letting request continue without a accessToken',
          );
          resolve('');

          return;
        }

        // Ensure user session is bye bye bye so that the auth states are NSYNC between our app and accounts
        destroySession(req, res);

        if (res) {
          res.sendStatus(UNAUTHORIZED_HTTP_STATUS_CODE);
        }

        resolve('');

        return;
      }

      resolve(formatAuthToken(accessToken));
    });
  });
