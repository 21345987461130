import { format } from 'url';

import {
  EARNINGS_ADJUSTMENTS,
  EARNINGS_AGGREGATE_ALLTIME,
  EARNINGS_BY_DATE,
  EARNINGS_GALLERY,
  EARNINGS_GRAPH,
  EARNINGS_HEADER_URL,
  EARNINGS_HIGHLIGHTS_URL,
  EARNINGS_LEVELS,
  EARNINGS_MEDIA_SUMMARY_URL,
  EARNINGS_OVERVIEW_URL,
  EARNINGS_TOP_PERFORMERS,
} from '@/constants/api';

export const getEarningsHeaderUrl = () => ({
  url: EARNINGS_HEADER_URL,
  formattedUrl: format({ pathname: EARNINGS_HEADER_URL }),
});

export const getEarningsHighlightsUrl = () => ({
  url: EARNINGS_HIGHLIGHTS_URL,
  formattedUrl: format({ pathname: EARNINGS_HIGHLIGHTS_URL }),
});

export const getEarningsMediaSummaryUrl = ({ mediaType = 'photo', displayColumn = [], page = 1, perPage = 10 }) => ({
  url: EARNINGS_MEDIA_SUMMARY_URL,
  formattedUrl: format({
    pathname: EARNINGS_MEDIA_SUMMARY_URL,
    query: { media_type: mediaType, display_column: displayColumn, page, per_page: perPage },
  }),
});

export const getEarningsByDateUrl = ({ date, displayColumn, page = 1, perPage = 10 }) => ({
  url: EARNINGS_BY_DATE,
  formattedUrl: format({
    pathname: EARNINGS_BY_DATE,
    query: { date, display_column: displayColumn, page, per_page: perPage },
  }),
});

export const getEarningsByMonthUrl = ({ year, month }) => {
  const query = { aggregation_period: 'day', year, month };

  return {
    url: EARNINGS_OVERVIEW_URL,
    formattedUrl: format({ pathname: EARNINGS_OVERVIEW_URL, query }),
  };
};

export const getAdjustmentsUrl = ({ page = 1, perPage = 10 }) => ({
  url: EARNINGS_ADJUSTMENTS,
  formattedUrl: format({
    pathname: EARNINGS_ADJUSTMENTS,
    query: { page, per_page: perPage },
  }),
});

export const getEarningsGraphUrl = ({ year, month, aggregationPeriod, monthsAgo, groupBy = [''], locale }) => {
  const externalParams = new URLSearchParams({
    year,
    month,
    aggregation_period: aggregationPeriod,
    months_ago: monthsAgo,
  });
  const internalParams = new URLSearchParams({
    year,
    month,
    aggregation_period: aggregationPeriod,
    months_ago: monthsAgo,
    locale,
  });

  if (groupBy.length) {
    externalParams.append('group_by', groupBy.toString());
    internalParams.append('group_by', groupBy.toString());
  }

  return {
    url: `${EARNINGS_OVERVIEW_URL}?${externalParams}`,
    internalUrl: `${EARNINGS_GRAPH}?${internalParams}`,
  };
};

export const getEarningsAggregateAlltimeUrl = ({ groupBy }) => ({
  url: EARNINGS_AGGREGATE_ALLTIME,
  formattedUrl: format({
    pathname: EARNINGS_AGGREGATE_ALLTIME,
    query: { group_by: groupBy },
  }),
});

export const getEarningsLevelsUrl = (searchParam) => ({
  url: `${EARNINGS_LEVELS}?${new URLSearchParams(searchParam).toString()}`,
});

export const getEarningsGalleryUrl = ({
  mediaType, // photo or video
  page = 1,
  perPage = 10,
  sort = 'desc', // asc or desc
  monthsAgo,
  keywordsPerMedia,
  setId,
}) => {
  const query = new URLSearchParams({
    per_page: perPage,
    page,
    sort,
  });

  // What media type to query: photo, video.
  // If this parameter is absent then CC-API returns both photo and video.
  if (mediaType) {
    query.append('media_type', mediaType);
  }

  // How many previous months of data we want to see
  // If this parameter is absent then CC-API returns all time top earners
  // It not is impossible to query current month’s data with this endpoint
  if (monthsAgo) {
    query.append('months_ago', monthsAgo);
  }

  // Maximum keywords to include per asset
  // If this parameter is absent then CC-API returns all keywords
  if (keywordsPerMedia != null) {
    query.append('keywords_per_media', keywordsPerMedia);
  }

  // If this parameter is present then CC-API limits the assets to specific set ID (collection ID)
  // This parameter also overrides mediaType
  if (setId != null) {
    query.append('set_id', setId);
  }

  return {
    url: EARNINGS_GALLERY,
    formattedUrl: `${EARNINGS_GALLERY}?${query}`,
  };
};

export const getEarningsTopPerformersUrl = ({ monthsAgo, mediaType = 'photo' }) => {
  const query = new URLSearchParams({ media_type: mediaType, keywords_per_media: 5 });

  if (monthsAgo) {
    query.append('months_ago', monthsAgo);
  }

  return {
    url: EARNINGS_TOP_PERFORMERS,
    formattedUrl: `${EARNINGS_TOP_PERFORMERS}?${query}`,
  };
};
