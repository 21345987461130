import { color } from '@sstk/design-tokens/base/color';

import { spacing } from '../spacing';

export const MuiChipOverrides = {
  styleOverrides: {
    root: {
      borderRadius: spacing('xs'),
      padding: spacing(1, 0.75, 0.875, 1.5),
      margin: 0,
    },
    label: { padding: 0 },
    deleteIcon: {
      padding: 0,
      marginLeft: spacing(1),
      marginRight: 0,
      color: color.gray['onyx-87'],
      fontSize: spacing(2),
    },
  },
};
