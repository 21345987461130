import { createContext } from 'react';

import { ValueOf } from '@/types/utilities';
import { noop } from '@/utils/noop';

import { WidgetStates } from './constants';

type WidgetStatesType = ValueOf<typeof WidgetStates>;

type UploadContextType = {
  uploadList: any[];
  enqueueUpload: (uploadObj: any) => void;
  enqueueFailedUpload: (uploadObj: any) => void;
  cancelAllUploads: () => void;
  clearList: () => void;
  uploadsInProgress: any[];
  progress: number;
  widgetState: WidgetStatesType;
  setWidgetState: (state: WidgetStatesType) => void;
  warningText: React.MutableRefObject<string>;
  mutationHandler: React.MutableRefObject<() => void>;
};

export const UploadsContext = createContext<UploadContextType>({
  uploadList: [],
  enqueueUpload: noop,
  enqueueFailedUpload: noop,
  cancelAllUploads: noop,
  clearList: noop,
  uploadsInProgress: [],
  progress: 0,
  widgetState: WidgetStates.Closed,
  setWidgetState: noop,
  warningText: {
    current: '',
  },
  mutationHandler: {
    current: noop,
  },
});
