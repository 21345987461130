import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { Link } from '@/components/Link';
import { C2N_FOOTER_LINKS } from '@/constants/global';
import { usePageFormState } from '@/hooks/usePageFormState';
import { useUnsavedDialog } from '@/hooks/useUnsavedDialog';
import { KeyboardArrowDownIcon } from '@/icons/KeyboardArrowDownIcon';
import { KeyboardArrowUpIcon } from '@/icons/KeyboardArrowUpIcon';
import { linkPropTypes } from '@/proptypes/link';
import { NAMESPACE_COMMON } from '@/translations/namespaces';
import { formatLink } from '@/utils/url';

const useStyles = (theme) => ({
  label: {
    fontSize: theme.tokens.font.size.s,
    color: theme.tokens.color['white-70'],
    '&:hover': {
      color: theme.tokens.color.white,
    },
    marginLeft: '20px',
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.tokens.color.white,
    fontSize: theme.tokens.font.size.s,
  },
  icon: {
    color: theme.tokens.color['white-70'],
  },
});

export const MobileLinks = ({ headerLabel, isExpandedDefault = false, links, dataAutomation = '' }) => {
  const { t } = useTranslation(NAMESPACE_COMMON);
  const { locale } = useRouter();

  const [isExpanded, setIsExpanded] = useState(isExpandedDefault);
  const toggleIsExpanded = () => setIsExpanded(!isExpanded);
  const theme = useTheme();
  const classes = useStyles(theme);
  const { pageHasDirtyForm } = usePageFormState();
  const { showCustomUnsavedDialog } = useUnsavedDialog(t('unsaved_changes'));

  const handleClick = (href, linkType) => {
    if (Object.values(C2N_FOOTER_LINKS).includes(href)) {
      if (pageHasDirtyForm) {
        showCustomUnsavedDialog(href);
      }
    } else {
      formatLink({ href, linkType, locale });
    }
  };

  return (
    <>
      <ListItem
        button
        onClick={toggleIsExpanded}
        aria-label={`${headerLabel}-content`}
        data-automation={dataAutomation}
      >
        <ListItemText
          primary={
            <Typography sx={{ color: classes.bold }} variant="bodyStaticMd">
              {t(headerLabel)}
            </Typography>
          }
        />

        {isExpanded ? (
          <KeyboardArrowUpIcon color="white" data-testid="KeyboardArrowUpIcon" />
        ) : (
          <KeyboardArrowDownIcon color="white" data-testid="KeyboardArrowDownIcon" />
        )}
      </ListItem>
      <Collapse in={isExpanded} timeout="auto" classes={{ container: classes.nested }}>
        <List>
          {links.map(({ label, linkTranslationKey, href, linkType, target }) => (
            <ListItem onClick={handleClick} key={label}>
              <Link href={formatLink({ href, linkType, locale })} target={target} data-testid={label}>
                <ListItemText disableTypography primary={t(linkTranslationKey)} sx={classes.label} />
              </Link>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

MobileLinks.propTypes = {
  headerLabel: PropTypes.string.isRequired,
  isExpandedDefault: PropTypes.bool,
  links: PropTypes.arrayOf(linkPropTypes).isRequired,
  dataAutomation: PropTypes.string,
};
