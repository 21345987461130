import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

import { NAMESPACE_COMMON } from '@/translations/namespaces';

const createStyles = ({ tokens: { color, font }, spacing, breakpoints }) => ({
  fontSize: font.size.xs,
  color: color['white-70'],
  marginTop: spacing('xs'),
  gridArea: 'copyright-text',
  [breakpoints.down('md')]: {
    padding: `${spacing('xs')} 0`,
    fontSize: font.size.xs,
    margin: 'auto',
  },
});

export const Copyright = ({ sx, ...props }) => {
  const { t } = useTranslation(NAMESPACE_COMMON);

  return (
    <>
      <Typography
        color="textSecondary"
        variant="bodyStaticXs"
        sx={[createStyles, ...(Array.isArray(sx) ? sx : [sx])]}
        {...props}
      >
        {t('contributor_footer_copyright', { CURRENT_YEAR: new Date().getFullYear() })}
      </Typography>
    </>
  );
};
