import { createBreakpoints } from '@mui/system';

export const values = {
  xs: 0, // phone
  sm: 600, // tablet
  md: 1024, // small laptop
  lg: 1280, // desktop
  xl: 1920, // large screen

  siteContainer: 1440,
};

export const breakpoints = createBreakpoints({ values });
