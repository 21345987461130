import { useQuery } from '@tanstack/react-query';

import { getCurrentUser } from '@/apiRoutes/currentUser';
import { QueryKeys } from '@/constants/server';
import { fetch } from '@/lib/fetch';
import { useUserStore } from '@/stores/useUserStore';
import { isBrowser } from '@/utils/isBrowser';

// https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/setuserid/
const setNewRelicUserId = (id) => {
  if (isBrowser && window.NREUM) {
    window.NREUM.setUserId(id ? `${id}` : null);
  }
};

const fetcher = (url) => fetch.get(url).then((res) => res.data);

export const useFetchUser = () => {
  const isLoggedIn = useUserStore((state) => state.isLoggedIn);

  const { data: user, error } = useQuery({
    enabled: isLoggedIn,
    queryKey: [QueryKeys.currentUser],
    queryFn: () => fetcher(getCurrentUser().formattedUrl),
    staleTime: 30 * 60 * 1000,
  });

  setNewRelicUserId(user?.details?.account?.id);

  return {
    error,
    isLoading: !user && !error && isLoggedIn,
    user,
    isOffsetUser: user?.details?.businessClassifications?.includes('offset'),
  };
};
