import { ButtonProps } from '@mui/material/Button';

import { ButtonWithoutTracking as FormButton } from '@/components/Form/FormElements/Button/ButtonWithoutTracking';
import { withClickTracking } from '@/lib/analytics/react-data-tracking';

interface ExtendedButtonProps extends ButtonProps {
  trackAction: string;
  sxTypography?: object;
  target?: string;
}

const UntrackedButton: React.FC<ExtendedButtonProps> = function UntrackedButton(props) {
  return <FormButton {...props} />;
};

export const Button = withClickTracking(UntrackedButton);
