import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { analyticsComponentWrapper } from '@/lib/analytics/analyticsComponentWrapper';

import { createSocialMediaStyles } from './styles';

// FIXME: Replace this custom anchor component with the one created in this Ticket: https://shutterstock-jira.codefactori.com/browse/CONTRIB-903
// This will be a common component, wrapped with analytics.
const Anchor = analyticsComponentWrapper(styled('a')({}));

/**
 * @param {Array<Object>} iconData
 * @param {string} iconData[].ariaLabel `aria-label` for anchor link
 * @param {string} iconData[].href
 * @param {import('@mui/material/styles').SvgIconComponent} iconData[].IconComponent
 * @param {Object=} iconOptions
 * @param {string=} iconOptions.color
 * @param {string=} iconOptions.hoverColor
 * @param {string=} iconOptions.size Icon size, e.g. "small", "medium". See `ICON_SIZE` in '@/constants/icons'. Case-insensitive.
 * @param {string=} iconOptions.justifyContent
 * @param {Object=} sx
 * @param {Object=} anchorProps Override props on Anchor component
 */
export const SocialMediaShareIcons = ({ iconData, sx = {}, iconOptions = {}, anchorProps = {} }) => {
  const classes = createSocialMediaStyles(useTheme(), iconOptions);

  return (
    <Box sx={[classes, sx]}>
      {iconData.map(({ href, IconComponent, ariaLabel }) => (
        <Anchor
          data-testid={ariaLabel}
          aria-label={ariaLabel}
          key={ariaLabel}
          href={href}
          target="_blank"
          rel="noreferrer"
          {...anchorProps}
        >
          <IconComponent />
        </Anchor>
      ))}
    </Box>
  );
};

SocialMediaShareIcons.propTypes = {
  iconData: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      IconComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
      ariaLabel: PropTypes.string,
    }),
  ).isRequired,
  iconOptions: PropTypes.shape({
    color: PropTypes.string,
    hoverColor: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'standard']),
    justifyContent: PropTypes.string,
  }),
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
  anchorProps: PropTypes.shape({}),
};
