import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'next-i18next';

import { Link } from '@/components/WithTracking';
import { NavTrackActions } from '@/constants/analytics';
import { CONTRIBUTOR_LOGO, OFFSET_LOGO, SHUTTERSTOCK_TITLE_LOGO, SITE_ROUTES } from '@/constants/global';
import { useFetchUser } from '@/hooks/useFetchUser';
import { NAMESPACE_COMMON } from '@/translations/namespaces';

const IMAGE_WIDTH_FACTOR = {
  [OFFSET_LOGO]: {
    DESKTOP: 20,
    MOBILE: 18,
    XS_MOBILE: 18,
  },
  [CONTRIBUTOR_LOGO]: {
    DESKTOP: 31,
    MOBILE: 26,
    XS_MOBILE: 21,
  },
  [SHUTTERSTOCK_TITLE_LOGO]: {
    DESKTOP: 20,
    MOBILE: 20,
    XS_MOBILE: 20,
  },
};

const createStyles = ({ spacing, breakpoints }) => ({
  container: {
    paddingLeft: spacing('m'),
    [breakpoints.down('md')]: {
      marginRight: spacing(2),
    },

    '& a': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  skeleton: {
    width: spacing('4xl'),
    height: spacing(4), // 4 * 8 = 32px
    [breakpoints.down('md')]: {
      marginRight: spacing('s'),
    },
  },
});

export const SiteHeaderLogo = ({ sx }) => {
  const theme = useTheme();
  const classes = createStyles(theme);
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm')); // mobile devices
  const isXsMobileView = useMediaQuery('(max-width:350px)'); // extra-small mobile devices
  const { t } = useTranslation(NAMESPACE_COMMON);
  const LOGO_ALT_TEXT = t('shutterstock_contributor');
  const { isOffsetUser, isLoading } = useFetchUser();

  const logoImage = isOffsetUser ? OFFSET_LOGO : CONTRIBUTOR_LOGO;

  let imgWidthFactor = IMAGE_WIDTH_FACTOR[logoImage].DESKTOP;

  if (isMobileView) {
    imgWidthFactor = IMAGE_WIDTH_FACTOR[logoImage].MOBILE;
  } else if (isXsMobileView) {
    imgWidthFactor = IMAGE_WIDTH_FACTOR[logoImage].XS_MOBILE;
  }

  return (
    <Box sx={[classes.container, sx]}>
      {isLoading === false ? (
        <Link trackAction={NavTrackActions.contributorHomeLink} href={SITE_ROUTES.HOME_PAGE}>
          <img
            data-testid="site-header-logo"
            src={logoImage}
            alt={LOGO_ALT_TEXT}
            width={theme.spacing(imgWidthFactor)}
          />
        </Link>
      ) : (
        <Skeleton sx={classes.skeleton} animation="wave" data-testid="logo-skeleton" />
      )}
    </Box>
  );
};
