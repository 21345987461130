/**
 * Track actions
 */
export const CommonTrackActions = Object.freeze({
  cancel: 'cancel',
  confirm: 'confirm',
  add: 'add',
  delete: 'delete',
  submit: 'submit',
  open: 'open',
  copyText: 'copy_text',
  previous: 'previous',
  next: 'next',
  select: 'select',
  close: 'close',
  fileUpload: 'file_upload',
  save: 'save',
  download: 'download',
  left: 'left',
  right: 'right',
  remove: 'remove',
  edit: 'edit',
  view: 'view',
  information: 'information',
  more: 'more',
  clear: 'clear',
  create: 'create',
  search: 'search',
  filter: 'filter',
  apply: 'apply',
  upload: 'upload',
  toggle: 'toggle',
  menuItem: 'menu_item',
  stay: 'stay',
  leave: 'leave',
  deselect: 'deselect',
  uploadGoToPortfolio: 'uploadGoToPortfolio',
  assetUploaded: 'asset_uploaded',
  assetSubmitted: 'asset_submitted',
  logout: 'Logout',
  errorOrWarning: 'ErrorOrWarning',
});

export const PortfolioTrackActions = Object.freeze({
  addToCollection: 'add_to_collection',
  seeLargePreview: 'preview',
  deselectAll: 'deselect_all',
  csvUpload: 'csv_upload',
  markAllAsCorrect: 'mark_all_as_correct',
  keywordSuggestionTool: 'keyword_suggestion_tool',
  feedback: 'feedback',
  addAll: 'add_all',
  imagesMediaTypeToggle: 'images_media_type_dropdown',
  videosMediaTypeToggle: 'videos_media_type_dropdown',
});

export const AccountsTrackActions = Object.freeze({
  connectPayoneerAccount: 'connect_payoneer_account',
});

export const CollectionTrackActions = Object.freeze({
  moreActions: 'more_actions',
  newCollection: 'new_collection',
});

export const ContactTrackActions = Object.freeze({
  heroImage: 'hero_image',
  heroImageContributor: 'hero_image_contributor',
});

export const TaxCenterTrackActions = Object.freeze({
  verifyPassword: 'verify_password',
});

export const NavTrackActions = Object.freeze({
  collectionsLink: 'collections_link',
  marketplaceLink: 'marketplace_link',
  portfolioLink: 'portfolio_link',
  contributorProfileLink: 'contributor_profile_link',
  portfolioNotSubmittedLink: 'portfolio_not_submitted_link',
  portfolioPendingLink: 'portfolio_pending_link',
  portfolioReviewedLink: 'portfolio_reviewed_link',
  contact_link: 'contact_link',
  home_link: 'home_link',
  legalLink: 'legal_link',
  signUpLink: 'sign_up_link',
  loginLink: 'login_link',
  shotListLink: 'shot_list_link',
  youtubeLink: 'youtube_link',
  contributorBlogLink: 'contributor_blog_link',
  globalEarningsLink: 'global_earnings_link',
  exploreToolsLink: 'explore_tools_link',
  contributorAppLink: 'contributor_app_link',
  accountSettingsLink: 'account_settings_link',
  releasePreviewLink: 'release_preview_link',
  contributorSupportCenter: 'contributor_support_center',
  shutterstockHomeLink: 'shutterstock_home_link',
  contributorHomeLink: 'contributor_home_link',
  emptyStateLink: 'empty_state_link',
  trendingKeywordsLink: 'trending_keywords_link',
});

/**
 * Page sections
 */

export const CommonPageSections = Object.freeze({
  unsavedDialog: 'unsaved_dialog',
  header: 'header',
});

export const AccountsPageSections = Object.freeze({
  publicProfile: 'public_profile',
  accountSettingsForm: 'account_settings_form',
  accountSettingsFormPayoneer: 'account_settings_form_payoneer',
});

export const BulkEditorPageSections = Object.freeze({
  selectionStatus: 'selection_status',
  bulkEditor: 'bulk_editor',
});

export const CollectionsPageSections = Object.freeze({
  collections: 'collections',
  subPageHeader: 'sub_page_header',
  searchDrawer: 'search_drawer',
  subPage: 'sub_page',
});

export const ContactPageSections = Object.freeze({
  // accounts
  changes: 'changes',
  logInIssues: 'log_in_issues',
  searchResultsAndIndexing: 'search_results_and_indexing',
  earningsPayoutsTaxes: 'earnings_payouts_taxes',
  warnings: 'warnings',
  // signup & registration
  signupFirstTime: 'signup_first_time',
  verificationRegistration: 'verification_registration',
  referrals: 'referrals',
  // submitting & portfolio
  portfolioTools: 'portfolio_tools',
  uploadingAndSubmittingContent: 'uploading_and_submitting_content',
  // review & rejection
  editorialContentCredentials: 'editorial_content_credentials',
  contentReview: 'content_review',
  releasesTrademarksMetadata: 'releases_trademarks_metadata',
  // legal
  contentMisuse: 'content_misuse',
  infringement: 'infringement',
  // report a bug
  reportABug: 'report_a_bug',
  // mobile
  contributorApp: 'contributor_app',
  // music
  music: 'music',
  // other
  other: 'all_other_issues',
  contactUs: 'contact_us',
});
export const DashboardPageSections = Object.freeze({
  dashboard: 'dashboard',
  blogs: 'blogs',
  sliderActions: 'slider_actions',
  carouselHero: 'carousel_hero',
  collections: 'collections',
  contentfulPromotion: 'contentful_promotion',
  emptyEarnings: 'empty_earnings',
  latestDownloadMap: 'latest_download_map',
  portfolioStatus: 'portfolio_status',
  trendingKeywords: 'trendingKeywords',
});

export const DataCatalogPageSections = Object.freeze({
  dataCatalog: 'data_catalog',
  dataCatalogPhoto: 'data_catalog_photo',
  dataCatalogVideo: 'data_catalog_video',
  grid: 'grid',
});

export const EarningsPageSections = Object.freeze({
  earnings: 'earnings',
  topPerformers: 'top_performers',
  dateDialog: 'date_dialog',
  payoutScaleTable: 'payouts_scale_table',
});

export const FormPageSections = Object.freeze({
  formHandler: 'form_handler',
});

export const LegalPageSections = Object.freeze({
  legal: 'legal',
  legalCard: 'legal_card',
  legalLearnMore: 'legal_learn_more',
  legalTerms: 'legal_terms',
});

export const LOHPPageSections = Object.freeze({
  lohp: 'lohp',
  footer: 'footer',
  resources: 'resources',
  firstStorytellingSection: 'first_storytelling_section',
  secondStorytellingSection: 'second_storytelling_section',
});

export const NavigationPageSections = Object.freeze({
  menuAnnouncement: 'menu_announcement',
  submenuDrawer: 'submenu_drawer',
});

export const GlobalPageSections = Object.freeze({
  contributorRoot: 'contributor_root',
  notificationStrip: 'notification_strip',
});

export const PortfolioPageSections = Object.freeze({
  assetCheckbox: 'asset_checkbox',
  moreActions: 'more_actions',
  assetCarousel: 'asset_carousel',
  assetSliderCard: 'asset_slider_card',
  csvErrorDialogHeader: 'csv_error_dialog_header',
  csvErrorDialog: 'csv_error_dialog',
  csvUploadHeader: 'csv_upload_header',
  csvUploadDialog: 'csv_upload_dialog',
  deleteDialog: 'delete_dialog',
  edit_dialog: 'edit_dialog',
  emptyState: 'empty_state',
  dynamicEmptyState: 'dynamic_empty_state',
  copy: 'copy',
  keywordInput: 'keyword_input',
  infoDialog: 'info_dialog',
  legacyBanner: 'legacy_banner',
  notSubmittedTab: 'not_submitted_tab',
  pendingTab: 'pending_tab',
  recentlyReviewedTab: 'recently_reviewed_tab',
  marketplaceCatalogTab: 'marketplace_catalog_tab',
  uploaderDialog: 'uploader_dialog',
  uploadErrorContent: 'upload_error_content',
  uploadErrorNotificationStrip: 'upload_error_notification_strip',
  bulkNotSubmittedPhoto: 'bulk_not_submitted_photo',
  bulkPendingPhoto: 'bulk_pending_photo',
  bulkReviewedApprovedPhoto: 'bulk_reviewed_approved_photo',
  bulkMarketplaceCatalogPhoto: 'bulk_marketplace_catalog_photo',
  bulkNotSubmittedVideo: 'bulk_not_submitted_video',
  bulkPendingVideo: 'bulk_pending_video',
  bulkReviewedApprovedVideo: 'bulk_reviewed_approved_video',
  bulkMarketplaceCatalogVideo: 'bulk_marketplace_catalog_video',
  notSubmittedPhoto: 'not_submitted_photo',
  pendingPhoto: 'pending_photo',
  reviewedApprovedPhoto: 'reviewed_approved_photo',
  marketplaceCatalogPhoto: 'marketplace_catalog_photo',
  notSubmittedVideo: 'not_submitted_video',
  pendingVideo: 'pending_video',
  reviewedApprovedVideo: 'reviewed_approved_video',
  marketplaceCatalogVideo: 'marketplace_catalog_video',
  bulkDataLicensingPhoto: 'bulk_data_licensing_photo',
  bulkDataLicensingVideo: 'bulk_data_licensing_video',
  dataLicensingPhoto: 'data_licensing_photo',
  dataLicensingVideo: 'data_licensing_video',
  bulkRejectedPhoto: 'bulk_rejected_photo',
  bulkRejectedVideo: 'bulk_rejected_video',
  rejectedPhoto: 'rejected_photo',
  rejectedVideo: 'rejected_video',
  bulkReviewedPhoto: 'bulk_reviewed_photo',
  bulkReviewedVideo: 'bulk_reviewed_video',
  reviewedPhoto: 'reviewed_photo',
  reviewedVideo: 'reviewed_video',
  inlineEditPanelFormHandler: 'inline_edit_panel_form_handler',
  returnedPhoto: 'returned_photo',
  returnedVideo: 'returned_video',
  bulkReturnedPhoto: 'bulk_returned_photo',
  bulkReturnedVideo: 'bulk_returned_video',
});

export const ReferralPageSections = Object.freeze({
  promotion: 'promotion',
  socialPanelCard: 'social_panel_card',
  referrals: 'referrals',
});

export const ReleasePageSections = Object.freeze({
  releases: 'releases',
  filters: 'filters',
  accordion: 'accordion',
  mobileGrid: 'mobile_grid',
  errorDialog: 'error_dialog',
  drawer: 'drawer',
  mobileFilter: 'mobile_filter',
  mobileSearchBar: 'mobile_search_bar',
  searchBar: 'search_bar',
});

export const SiteHeaderPageSections = Object.freeze({
  siteHeader: 'site_header',
  desktopUploadButton: 'desktop_upload_button',
  userIcon: 'user_icon',
});
export const TablePageSections = Object.freeze({
  row: 'row',
  pagination: 'pagination',
});
export const TaxCenterPageSections = Object.freeze({
  taxCenter: 'tax_center',
  treatyCountries: 'treaty_countries',
  externalDownloadDialog: 'external_download_dialog',
  externalTaxDownloadPage: 'external_tax_download_page',
  taxFormReview: 'tax_form_review',
  w8benForm: 'w8ben_form',
  w9Form: 'w9_form',
});

export const UploadSnackbarPageSections = Object.freeze({
  uploadSnackbar: 'upload_snackbar',
  confirmCancel: 'confirm_cancel',
});

export const UserDetailsDrawerPageSections = Object.freeze({
  userDetailsDrawer: 'user_details_drawer',
  content: 'content',
});

export const SignupPageSections = Object.freeze({
  signUpAddress: 'sign_up_address',
  signUpWelcome: 'sign_up_welcome',
});

export const PaymentsPageSections = Object.freeze({
  paymentHistory: 'payment_history',
});

export const UnsupportedBrowserPageSections = Object.freeze({
  unsupportedBrowser: 'unsupported_browser',
});

export const ErrorPagesPageSections = Object.freeze({
  pageNotFound: 'page_not_found',
  unknownError: 'unknown_error',
});
