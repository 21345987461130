import { createTheme } from '@mui/material/styles';
import merge from 'lodash.merge';

import { themeBase } from './themeBase';
import { color, opacity } from './tokens';

const themeLight = merge(themeBase, {
  themeName: 'Shutterstock Light',
  form: {
    input: {
      minHeight: '40px',
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: color.black,
    },
    secondary: {
      main: '#FC3441',
    },
    info: {
      main: color.blue.azure,
    },
    success: {
      main: color.green.jungle,
    },
    warning: {
      main: color.yellow.amber,
    },
    error: {
      main: color.red.basic,
    },
    link: {
      main: 'rgba(0, 112, 240, 1)',
      secondary: '#43B9D3',
      contrastText: color.white,
      footer: color['white-70'],
    },
    lightGrey: {
      main: 'rgba(12, 18, 28, 0.38)',
    },
    lighterGrey: {
      main: 'rgba(12, 18, 28, 0.02)',
    },
    darkGrey: {
      main: 'rgba(12, 18, 28, 0.6)',
    },
    white: {
      main: color.white,
    },
    yellow: color.yellow,
    red: {
      ...color.red,
      alert: '#DD2E39',
    },
    assetStatus: {
      rejected: '#DD2E39',
      pending: color.yellow.amber,
      approved: color.green.jungle,
      marketplace: '#6A5CFA',
    },
    background: {
      default: color.white,
      secondary: color.gray.marble,
      contrast: '#303030',
      dark: '#0C121C',
      darkGrey: '#282C38',
      lightGrey: '#3d404a',
      subtotal: color.gray.marble,
      iron: color.gray.iron,
      shadedGrey: 'rgba(243,244,245, 1)',
      active: 'rgba(0, 112, 240, 1)',
      inactive: 'rgba(226, 227, 228, 1)',
      grey100: '#f5f5f5',
    },
    skeleton: {
      contrast: color['white-12'],
    },
    text: {
      main: color.gray['onyx-87'],
      primary: color.gray['onyx-87'],
      secondary: color.gray['onyx-60'],
      tertiary: color.gray['onyx-50'],
      disabled: color.gray['onyx-38'],
      inverse: color.white,
      error: color.red.rose,
    },
    buttonOverrides: {
      linkDisabledText: 'rgba(0, 112, 240, 0.38)',
      helpIconCompact: '#AEB0B3',
    },
    border1Color: color.gray['onyx-12'],
    border2Color: 'rgb(0,0, 0, 0.23)', // Matches default MUI borders
    border3Color: 'rgba(255, 255, 255, 0.2)',
    fadedWhite: 'rgba(255, 255, 255, 0.7)',
    border: {
      color1: color.gray['onyx-12'], // same as border1Color, move to here due to other border colors based on state.
      color2: 'rgb(0,0, 0, 0.23)', // same as border2Color, move to here due to other border colors based on state.
      color3: 'rgba(255, 255, 255, 0.2)', // same as border3Color, move to here due to other border colors based on state.
      focused: color.gray['onyx-87'],
      error: color.red.rose,
      selectedBar: color.black,
      light: 'rgba(12, 18, 28, 0.12)',
    },
    disabled: {
      opacity: opacity['38'],
      background: color.gray.marble,
    },
    modal: {
      backdrop: color.gray['onyx-87'],
    },
    button: {
      pressed: color.gray.onyx,
    },
    checkbox: {
      main: color.gray['onyx-12'],
      hover: 'rgba(12, 18, 28, 0.28)',
      checked: 'rgba(0, 112, 240, 1)',
      checkedHover: '#055BBC',
    },
    switch: {
      main: color.blue.azure,
      background: color.blue.azure,
    },
    status: {
      rejected: color.red.rose,
      pending: color.yellow.amber,
      approved: color.green.jungle,
      not_submitted: color.gray.marble,
    },
    graph: {
      primary: color.blue.azure,
      secondary: '#97C2F3',
      tertiary: color.blue.aqua,
    },
    shadow: {
      primary: 'rgba(0, 0, 0, 0.4)',
    },
    carousel: {
      primary: color.white,
      hover: 'rgba(12, 18, 28, 0.38)',
      active: 'rgba(12, 18, 28, 0.2)',
    },
  },
});

// For optimal SSR performance we compute the initial theme at build time
export const sstkLightTheme = createTheme(themeLight);
