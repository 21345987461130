import { useTranslation } from 'next-i18next';
import { FC, useEffect, useState } from 'react';

import { Dropzone } from '@/components/Dropzone';
import { EmptyState } from '@/components/Portfolio/EmptyState/EmptyState';
import { useHandleUpload } from '@/components/UploadSnackbar/useHandleUpload';
import { useFetchUploadingOk } from '@/hooks/useFetchUploadingOk';
import { useUploadingDisabledNotification } from '@/hooks/useUploadingDisabledNotification';
import { NAMESPACE_COMMON } from '@/translations/namespaces';

type Props = {
  displayError?: (text: string) => void;
  successfulUploadHandler?: () => void;
  failedUploadHandler?: () => void;
};

export const EmptyStateWithDropzone: FC<Props> = function EmptyStateWithDropzone({
  displayError,
  failedUploadHandler,
  successfulUploadHandler,
}) {
  const [maxLimit, setMaxLimit] = useState(false);
  const [incorrectFileType, setIncorrectFileType] = useState(false);
  const { t } = useTranslation(NAMESPACE_COMMON);

  const { handleOnChange: handleOnUploadChange } = useHandleUpload({
    failedUploadHandler,
    successfulUploadHandler,
  });
  const { isLoading, uploadingOk: isUploadingAllowed = false } = useFetchUploadingOk();
  const uploadingDisabledNotification = useUploadingDisabledNotification();

  const handleMaxUploadLimit = (value: boolean, filesToUpload: File[]) => {
    setMaxLimit(value);

    if (filesToUpload?.length === 0) {
      setIncorrectFileType(true);
    }
  };

  useEffect(() => {
    if (maxLimit && !incorrectFileType) {
      displayError?.(t('upload_limit_notification'));
      setMaxLimit(false);
    }

    if (incorrectFileType) {
      displayError?.(t('upload_failed_unsupported_file_type'));
      setIncorrectFileType(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxLimit]);

  const onDropFile = (filesToUpload: File[]) => {
    if (isLoading) return;

    if (!isUploadingAllowed) {
      uploadingDisabledNotification();

      return;
    }

    handleOnUploadChange(filesToUpload);
    handleMaxUploadLimit(filesToUpload.length > 1 || !filesToUpload.length, filesToUpload);
  };

  return (
    <Dropzone onDropFile={onDropFile}>
      <EmptyState
        onMaxUploadLimit={handleMaxUploadLimit}
        successfulUploadHandler={successfulUploadHandler}
        failedUploadHandler={failedUploadHandler}
      />
    </Dropzone>
  );
};
