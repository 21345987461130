export const createStyles = ({ palette, tokens, transitions, breakpoints, spacing }) => ({
  root: {
    position: 'relative',
    padding: `0 ${spacing('m')} ${spacing('m')}`,
    marginTop: 'auto',

    backgroundColor: palette.background.dark,
    transition: transitions.create('margin', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
  },
  container: {
    padding: 0,

    [breakpoints.up('md')]: {
      padding: `0 ${spacing('s')}`,
    },
  },
  footerContent: {
    [breakpoints.up('md')]: {
      paddingBottom: spacing('s'),
    },
  },
  footerBorder: {
    marginTop: spacing('xl'),
    borderTop: `1px solid ${palette.border3Color}`,
  },
  footerTail: {
    paddingTop: spacing('m'),
    marginTop: spacing('m'),
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    [breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: spacing('m'),
      paddingTop: spacing('s'),
    },
  },
  linkContainer: {
    order: 2,
  },
  copyrightSocialMediaIconsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    paddingTop: spacing('m'),
    marginTop: spacing('m'),
    gridTemplateAreas: `"copyright-text social-media-icons"`,
    [breakpoints.down('md')]: {
      marginTop: spacing('m'),
      paddingTop: spacing('s'),
      gridTemplateColumns: 'repeat(1, 1fr)',
      gridTemplateAreas: `
        "social-media-icons"
        "copyright-text"
      `,
      margin: 'auto',
      marginBottom: spacing(10),
    },
  },
  socialMediaIconsContainer: {
    display: 'grid',
    gap: spacing(2.5),
    marginLeft: 'auto',
    gridTemplateColumns: 'repeat(7, 1fr)',
    [breakpoints.down('md')]: {
      margin: 'auto',
    },
    [breakpoints.down('sm')]: {
      gap: spacing(1.25),
    },
    gridArea: 'social-media-icons',
  },
  signUpFooterLinksContainer: {
    my: spacing(2.5),
    gap: spacing(2),
    [breakpoints.up('md')]: {
      gap: spacing(6),
    },
    justifyContent: 'center',
  },
  smallFooterLink: {
    fontSize: tokens.font.size.xs,
    color: tokens.color['white-70'],
    '&:hover': {
      color: tokens.color.white,
    },
    textDecoration: 'none',
    [breakpoints.down('sm')]: {
      mb: spacing(4),
    },
  },
});
