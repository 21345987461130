import { useEffect } from 'react';

import { UnsavedModalContent } from '@/components/UnsavedModalContent/UnsavedModalContent';

import { useConfirmationComponent } from './useConfirmationComponent';
import { usePageFormState } from './usePageFormState';

export const useUnsavedDialog = (message = '') => {
  const { openConfirmation, closeConfirmation } = useConfirmationComponent();
  const { pageHasDirtyForm } = usePageFormState();

  // Handles user reloading the page or reloading the full browser
  useEffect(() => {
    const beforeUnloadHandler = (e) => {
      (e || window.event).returnValue = message;

      return message;
    };

    if (pageHasDirtyForm) {
      window.addEventListener('beforeunload', beforeUnloadHandler);
    } else {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    }

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [pageHasDirtyForm, message]);

  const showCustomUnsavedDialog = (href) =>
    openConfirmation(<UnsavedModalContent closeModal={closeConfirmation} href={href} />);

  return {
    showCustomUnsavedDialog,
  };
};
