import { useRouter } from 'next/router';
import { useCallback } from 'react';

import { DEFAULT_CURRENCY_FORMAT } from '@/constants/global';

export const useCurrencyFormatter = (options = {}) => {
  const { locale } = useRouter();
  const formatOptions = { ...DEFAULT_CURRENCY_FORMAT, ...options };
  const formatter = Intl.NumberFormat(locale, formatOptions);

  return useCallback((value) => formatter.format(value || 0), [formatter]);
};
