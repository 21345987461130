/* eslint-disable @typescript-eslint/ban-ts-comment */
import MUIBackdrop from '@mui/material/Backdrop';
import { styled } from '@mui/material/styles';

export const Backdrop = styled(MUIBackdrop)(({ theme }) => ({
  zIndex: -1,
  position: 'fixed',
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  // @ts-ignore
  backgroundColor: theme.palette.modal.backdrop,
}));
