import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const IllustrationsIcon = (props: Partial<SvgIconProps>) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 22.013H20L22 20.013H4L2 22.013ZM9.084 13.796L10.221 14.932C9.531 15.69 8.591 16.186 7.437 16.581C7.835 15.438 8.333 14.491 9.084 13.796V13.796ZM11.707 9.72C14.427 7 17.523 5.036 18.465 4.628L19.385 5.547C18.974 6.492 17.005 9.594 14.293 12.306C13.104 13.494 11.941 13.856 11.48 13.851L10.162 12.533C10.159 12.067 10.521 10.907 11.707 9.72V9.72ZM12 15.713C12.896 15.53 14.218 15.21 15.707 13.72C19.455 9.972 21.472 6.23 21.569 6.049L22.014 5.05L18.963 2L17.964 2.444C17.783 2.54 14.052 4.547 10.293 8.306C8.802 9.797 8.636 11.111 8.454 12.007C6.527 12.877 5.832 15.521 5.58 16.709L5.183 18.825L7.129 18.395C8.32 18.147 11.134 17.656 12 15.713V15.713Z"
    />
  </SvgIcon>
);
