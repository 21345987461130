import { border, font, size } from '../tokens';

const BUTTON_SMALL_PAD_X = '14px';
const BUTTON_MEDIUM_PAD_X = '40px';
const BUTTON_LARGE_PAD_X = '80px';

const common = {
  fontWeight: 'bold',
  textTransform: 'none',
  minWidth: '10px',
};

const buttonSmall = {
  height: size.density.high,
  borderRadius: border.radius.l,
  fontSize: '15px',
  lineHeight: '15px',
  paddingLeft: BUTTON_SMALL_PAD_X,
  paddingRight: BUTTON_SMALL_PAD_X,
};

const buttonMedium = {
  height: size.density.high,
  borderRadius: border.radius.pill,
  paddingLeft: BUTTON_MEDIUM_PAD_X,
  paddingRight: BUTTON_MEDIUM_PAD_X,
};

const buttonLarge = {
  height: size.density.base,
  borderRadius: border.radius.pill,
  lineHeight: font.size.l,
  fontSize: font.size.l,
  paddingLeft: BUTTON_LARGE_PAD_X,
  paddingRight: BUTTON_LARGE_PAD_X,
};

export const MuiButtonOverrides = {
  defaultProps: {
    disableElevation: true,
  },
  styleOverrides: {
    root: common,
    containedSizeSmall: buttonSmall,
    outlinedSizeSmall: buttonSmall,
    contained: buttonMedium,
    outlined: buttonMedium,
    containedSizeLarge: buttonLarge,
    outlinedSizeLarge: buttonLarge,
  },
};
