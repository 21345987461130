import { useCallback } from 'react';

import { CommonTrackActions } from '@/constants/analytics';
import { useAnalyticsContext } from '@/lib/analytics';

import { UploadAnalyticData } from './types';

export const useAssetUploadedAnalyticsEvent = () => {
  const { analytics } = useAnalyticsContext();

  return useCallback(
    ({ mediaId }: UploadAnalyticData) => {
      if (mediaId && analytics) {
        analytics.assetUploaded({
          media_id: mediaId,
          eventAction: CommonTrackActions.assetUploaded,
          eventCategory: 'userInteraction',
          eventLabel: CommonTrackActions.assetUploaded,
        });
      }
    },
    [analytics],
  );
};
