import { getCurrentUser } from '@/apiRoutes/currentUser';

import { ContributorApi } from './api/contributor';

export class CurrentUser extends ContributorApi {
  async getCurrentUser() {
    const url = getCurrentUser().formattedUrl;

    const { data } = await this.get(url);

    return data;
  }
}
