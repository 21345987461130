import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

import { Link } from '@/components/Link';
import { C2N_FOOTER_LINKS } from '@/constants/global';
import { usePageFormState } from '@/hooks/usePageFormState';
import { useUnsavedDialog } from '@/hooks/useUnsavedDialog';
import { linkPropTypes } from '@/proptypes/link';
import { NAMESPACE_COMMON } from '@/translations/namespaces';
import { formatLink } from '@/utils/url';

const createStyles = ({ spacing, tokens }) => ({
  navLink: {
    color: tokens.color['white-70'],
    textDecoration: 'none',
    '&:hover': {
      color: tokens.color.white,
    },
    cursor: 'pointer',
  },
  headerTitle: {
    padding: spacing('s'),
    paddingLeft: 0,
    color: tokens.color.white,
    fontWeight: tokens.fontWeightBold,
  },
  listRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  listItem: {
    px: 0,
    mb: tokens.font.size.xs,
    marginBottom: 0,
  },
});

export const DesktopLinks = ({ headerLabel, links }) => {
  const { t } = useTranslation(NAMESPACE_COMMON);
  const { locale } = useRouter();
  const classes = createStyles(useTheme());
  const { pageHasDirtyForm } = usePageFormState();
  const { showCustomUnsavedDialog } = useUnsavedDialog(t('unsaved_changes'));

  const handleClick = (href, linkType) => {
    if (Object.values(C2N_FOOTER_LINKS).includes(href)) {
      if (pageHasDirtyForm) {
        showCustomUnsavedDialog(href);
      }
    } else {
      formatLink({ href, linkType, locale });
    }
  };

  return (
    <>
      <Typography variant="bodyStaticMd" sx={classes.headerTitle}>
        {t(headerLabel)}
      </Typography>
      <List sx={classes.listRoot}>
        {links.map(({ label, linkTranslationKey, href, target, linkType, options }) => (
          <ListItem onClick={handleClick} key={label} sx={classes.listItem}>
            <Link sx={classes.navLink} href={formatLink({ href, linkType, locale, options })} target={target}>
              {t(linkTranslationKey)}
            </Link>
          </ListItem>
        ))}
      </List>
    </>
  );
};
DesktopLinks.propTypes = {
  headerLabel: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(linkPropTypes).isRequired,
};
