import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const HomeIcon = (props: Partial<SvgIconProps>) => (
  <SvgIcon width="19" height="20" viewBox="-2 -2 22 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 17.981H12V9.981H6V17.981H2V9.808L8.994 2.814L16 9.825V17.981ZM9 0L0 8.98V19.981H7.995V11.981H10.003V19.981H18V8.992L9 0Z"
      fillOpacity="0.87"
    />
  </SvgIcon>
);
