import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

type ExpireProps = {
  children: React.ReactNode;
  onExpire: () => void;
  delay: number;
};

const Expire = ({ children, onExpire, delay }: ExpireProps) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onExpire();
    }, delay);

    return () => clearTimeout(timer);
  }, [delay, onExpire]);

  return <>{children}</>;
};

export const AccessibleLoader = () => {
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isLoadingFinished, setIsLoadingFinished] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const handleLoadingStart = () => {
      setIsPageLoading(true);
      setIsLoadingFinished(false);
    };
    const handleLoadingComplete = () => {
      setIsPageLoading(false);
      setIsLoadingFinished(true);
    };

    router.events.on('routeChangeStart', handleLoadingStart);
    router.events.on('routeChangeComplete', handleLoadingComplete);
    router.events.on('routeChangeError', handleLoadingComplete);

    return () => {
      router.events.off('routeChangeStart', handleLoadingStart);
      router.events.off('routeChangeComplete', handleLoadingComplete);
      router.events.off('routeChangeError', handleLoadingComplete);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isPageLoading && (
        <Box sx={visuallyHidden} component="span" role="alert">
          The page is loading.
        </Box>
      )}
      {isLoadingFinished && (
        <Expire delay={1000} onExpire={() => setIsLoadingFinished(false)}>
          <Box sx={visuallyHidden} component="span" role="alert">
            The page is finished loading.
          </Box>
        </Expire>
      )}
    </>
  );
};
