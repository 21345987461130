import { Theme } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { SSTKPalette, SSTKThemeTokens } from '@/theme/types';

export const getMenuProps = (tokens: SSTKThemeTokens, palette: SSTKPalette) => ({
  PaperProps: {
    sx: {
      backgroundColor: palette.background.darkGrey,
      color: tokens.color.white,
      '& .MuiMenuItem-root:hover': {
        background: palette.background.lightGrey,
      },
      '& .MuiMenuItem-root:first-of-type': {
        backgroundColor: palette.background.darkGrey,
      },
    },
  },
});

export const createStyles = (
  tokens: SSTKThemeTokens,
  spacing: any,
  breakpoints: any,
  color: CSSProperties['color'] | (({ palette }: Theme) => CSSProperties['color']) | undefined,
  noIcon: boolean,
) => ({
  inputRoot: {
    height: tokens.size.density.high,
    color: color || tokens.color.white,
    pl: noIcon ? 0 : 'auto',
  },
  languageSelectContainer: {
    border: `1px solid ${color ?? tokens.color['white-12']}`,
    borderRadius: tokens.border.radius['3xl'],
    '&:hover': {
      outline: `1px solid ${color ?? tokens.color['white-50']}`,
    },
    marginTop: `calc(${spacing('s')} + ${spacing('l')})`,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiSvgIcon-root': {
      color: color ?? tokens.color.white,
    },
    [breakpoints.down('md')]: {
      maxWidth: 'auto',
      marginTop: `calc(${spacing('s')})`,
      marginBottom: spacing('m'),
    },
  },
  globeAdornment: { display: noIcon ? 'none' : 'flex' },
});
