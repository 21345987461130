export const MuiAccordionOverrides = {
  styleOverrides: {
    root: {
      boxShadow: 'none',
      '::before': {
        backgroundColor: 'transparent',
      },
    },
  },
};
