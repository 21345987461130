/* eslint-disable react/no-unused-prop-types */
import React, { createContext, forwardRef, useCallback, useContext, useEffect, useRef } from 'react';

import { useDataTracking } from './analytics-wrapper.context';

interface DataTrackingContextRef {
  current: DataTrackingContext;
}

interface DataTrackingContext {
  name: string;
  data?: Record<string, unknown>;
  parent?: DataTrackingContextRef;
  value?: string;
}

export interface WithClickTrackingProps {
  trackAction: string;
  onClick: unknown;
}

const DataTrackingContextInstance: React.Context<DataTrackingContextRef | undefined> = createContext<
  DataTrackingContextRef | undefined
>(undefined);

const useDataTrackingContext = (): DataTrackingContextRef | undefined => useContext(DataTrackingContextInstance);

/**
 * Data tracking Context provider to help giving information and parameters for the action.
 * @param props
 * @returns
 */
export const DataTrackingContext = (props: React.PropsWithChildren<DataTrackingContext>) => {
  const { children, name, data } = props;
  const parentTrackingContext = useDataTrackingContext();
  const providerProps = useRef<DataTrackingContext>({ name: '' });

  useEffect(() => {
    // uncomment this line to debug or follow what happens
    // console.log(`updating context with new data ${name}`);
    providerProps.current = {
      name,
      data,
      parent: parentTrackingContext,
    };
  });

  return <DataTrackingContextInstance.Provider value={providerProps}>{children}</DataTrackingContextInstance.Provider>;
};

/**
 * HOC to add click tracking behaviour to elements with onClick.
 * @param Component
 * @returns
 */
export const withClickTracking = <T extends object>(Component: React.FC<T>) => {
  const NewComponent = forwardRef((props: T, ref) => {
    const analyticsContext = useDataTrackingContext();
    const dataTrackingInstance = useDataTracking();

    const { trackAction, onClick: originalOnClick, ...otherProps } = props as any;

    const newOnClick = useCallback(
      (args: any) => {
        if (trackAction) {
          const nestedContexts = getNestedContexts(analyticsContext);

          if (dataTrackingInstance) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            dataTrackingInstance.trackAction(trackAction, nestedContexts);
          }
        }

        if (originalOnClick) {
          return originalOnClick(args);
        }

        return null;
      },
      [trackAction, originalOnClick, analyticsContext, dataTrackingInstance],
    );

    return <Component ref={ref} {...otherProps} onClick={newOnClick} />;
  });

  const name = Component.displayName || Component.name;

  NewComponent.displayName = `logProps(${name})`;

  return NewComponent;
};

/**
 * Get all contexts nested into a single object response with all contexts
 * @param context
 * @param trackContexts
 * @returns object with all contexts
 */
function getNestedContexts(
  context: DataTrackingContextRef | undefined,
  trackContexts: Record<string, unknown> = { page_sections: [] },
) {
  const SPECIAL_CONTEXTS = {
    page_section(data: Record<string, unknown> | undefined) {
      const sectionValue = data?.value;

      if (sectionValue) {
        (trackContexts.page_sections as string[]).push(sectionValue as string);
      } else {
        throw new Error("Section doesn't have a value");
      }
    },
    // Add more special cases here if needed
    default(name: string, data: Record<string, unknown> | undefined) {
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      trackContexts[name] = { ...trackContexts[name], ...data };
    },
  };

  if (!context) return trackContexts;

  const { parent, name, data } = context.current;

  if (parent) {
    getNestedContexts(parent, trackContexts);
  }

  if (!name) {
    throw new Error("Context doesn't have a name");
  }

  // eslint-disable-next-line no-prototype-builtins
  if (SPECIAL_CONTEXTS.hasOwnProperty(name)) {
    // @ts-ignore
    SPECIAL_CONTEXTS[name](data);
  } else {
    SPECIAL_CONTEXTS.default(name, data);
  }

  return { track_contexts: trackContexts };
}
