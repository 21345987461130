export const classes = {
  avatar: ({ spacing }) => ({
    margin: `0 ${spacing('xs')} ${spacing('xs')}`,
    padding: spacing('m'),
  }),
  drawerProfileInfo: ({ palette, spacing }) => ({
    backgroundColor: palette.background.secondary,
    width: '100%',
    display: 'inline-flex',
    marginBottom: spacing('s'),
    '&&': {
      paddingTop: { xs: 0, md: spacing('l') },
    },
    boxShadow: 'none',
  }),
  drawerProfileTextInfo: ({ spacing }) => ({
    paddingTop: spacing('m'),
    display: 'block',
    width: '100%',
  }),
  drawerListItem: ({ spacing }) => ({
    paddingLeft: spacing('l'),
    '&:hover': {
      backgroundColor: 'rgb(0,0,0,0.1)',
      cursor: 'pointer',
    },
  }),
  userIcon: ({ spacing }) => ({
    backgroundColor: 'rgb(0,0,0,0.1)',
    height: `calc(${spacing('xl')} - ${spacing('s')})`,
    width: `calc(${spacing('xl')} - ${spacing('s')})`,
    '&:hover': {
      background: 'none',
      backgroundColor: 'rgb(0,0,0,0.1)',
    },
  }),
  userNameText: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  userIdText: ({ palette }) => ({
    fontSize: 13,
    color: palette.text.secondary,
  }),
};
