import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

import { NAMESPACE_COMMON } from '@/translations/namespaces';

const createStyles = ({ breakpoints, tokens, spacing }) => ({
  root: {
    color: tokens.color['white-70'],
    fontSize: tokens.font.size.sm,
    marginTop: spacing('l'),
    marginBottom: spacing('m'),
    padding: `${tokens.spacing.base} 0`,

    [breakpoints.down('md')]: {
      padding: `${tokens.spacing.xs} ${tokens.spacing.xs} 0 0`,
      fontSize: tokens.font.size.s,
      textAlign: 'center',
      marginBottom: spacing('s'),
    },
  },
});

export const StatsInfo = () => {
  const classes = createStyles(useTheme());
  const { t } = useTranslation(NAMESPACE_COMMON);

  return (
    <Typography sx={classes.root} variant="bodyStaticMd" color="textSecondary">
      {t('contributor_footer_contributor_stats')}
    </Typography>
  );
};
