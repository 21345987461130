import { ThemeProvider } from '@mui/material/styles';
import mediaQuery from 'css-mediaquery';
import merge from 'deepmerge';
import { useEffect, useMemo, useRef } from 'react';

import { sstkLightTheme } from '@/theme';
import { isProductionEnv } from '@/utils/envData';

type BrandProviderProps = {
  children: Readonly<React.ReactNode>;
  isMobileServer: Readonly<boolean>;
};

export const BrandProvider = ({ children, isMobileServer = false }: BrandProviderProps) => {
  const ref = useRef(false);

  useEffect(() => {
    // Output theme documentation to the console for non-production environments
    if (!isProductionEnv && !ref.current) {
      ref.current = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).theme = sstkLightTheme;
      // eslint-disable-next-line no-console
      console.info(`
      👩‍💻Tip: you can access the documentation 'theme' object directly in the console.
    `);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, sstkLightTheme]);

  const theme = useMemo(() => {
    const ssrMatchMedia = (query: string) => ({
      matches: mediaQuery.match(query, {
        // The estimated CSS width of the browser.
        width: isMobileServer ? '200px' : 'xl',
      }),
    });
    const mobileMediaServerTheme = {
      components: {
        MuiUseMediaQuery: {
          defaultProps: {
            ssrMatchMedia,
          },
        },
      },
    };

    return merge(sstkLightTheme, mobileMediaServerTheme);
  }, [isMobileServer]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
