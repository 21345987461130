import { IconButton } from '@/components/WithTracking/IconButton';
import { CommonTrackActions } from '@/constants/analytics';

import { MENU_ITEM_INFO } from './constants';

const createStyles = () => ({
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

export const MenuButton = ({ iconType }) => {
  const MenuItemIcon = MENU_ITEM_INFO[iconType].icon;

  return (
    <IconButton sx={createStyles} data-testid={`${iconType}_MenuButton`} trackAction={CommonTrackActions.menuItem}>
      <MenuItemIcon fontSize="medium" />
    </IconButton>
  );
};
