import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const PinterestIcon = (props: Partial<SvgIconProps>) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.477 2 2 6.477 2 12C2 16.236 4.636 19.854 8.355 21.312C8.268 20.521 8.189 19.308 8.391 18.443C8.572 17.663 9.563 13.473 9.563 13.473C9.563 13.473 9.264 12.874 9.264 11.988C9.264 10.598 10.07 9.56 11.073 9.56C11.926 9.56 12.339 10.2 12.339 10.968C12.339 11.826 11.792 13.108 11.51 14.298C11.275 15.293 12.01 16.104 12.991 16.104C14.769 16.104 16.135 14.23 16.135 11.525C16.135 9.131 14.415 7.457 11.958 7.457C9.112 7.457 7.442 9.591 7.442 11.797C7.442 12.656 7.773 13.578 8.187 14.079C8.268 14.178 8.28 14.265 8.256 14.365C8.18 14.682 8.011 15.36 7.978 15.5C7.935 15.683 7.833 15.722 7.644 15.634C6.395 15.052 5.614 13.227 5.614 11.759C5.614 8.605 7.906 5.708 12.221 5.708C15.69 5.708 18.386 8.18 18.386 11.483C18.386 14.93 16.213 17.703 13.197 17.703C12.184 17.703 11.231 17.177 10.905 16.556C10.905 16.556 10.404 18.465 10.282 18.933C10.056 19.801 9.447 20.89 9.039 21.554C9.975 21.844 10.969 22 12 22C17.523 22 22 17.522 22 12C22 6.477 17.523 2 12 2"
    />
  </SvgIcon>
);
