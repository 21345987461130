import Cookies from 'js-cookie';
import getConfig from 'next/config';

const {
  publicRuntimeConfig: { ENV },
} = getConfig();

export const getShutterstockCookieDomain = () => {
  // localhost cookies work slightly differently, they require return to be undefined (eslint doesn't like 'return;' here)
  if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') return undefined;

  if (window.location.hostname && window.location.hostname.includes('contributor-contributor-web-pr')) {
    return window.location.hostname;
  }

  switch (ENV) {
    case 'dev':
    case 'test':
      return '.dev.shutterstock.com';
    case 'qa':
      return '.qa.shutterstock.com';
    default:
      return '.shutterstock.com';
  }
};

export const setShutterstockCookies = ({ key, value, options = {} }) => {
  const keys = typeof key === 'string' ? [key] : key;
  const domain = getShutterstockCookieDomain();

  keys.forEach((cookieName) => {
    Cookies.set(cookieName, value, { domain, ...options });
  });
};
