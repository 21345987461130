import MUILink, { LinkProps as MUILinkProps } from '@mui/material/Link';
import NextLink from 'next/link';
import { forwardRef } from 'react';

type LinkProps = MUILinkProps & { useNextLink?: boolean };

export const Link = forwardRef(function Link({ href = '', useNextLink = true, ...restProps }: LinkProps, ref) {
  const isExternalLink = /^(https?:\/\/)|\/\//.test(href);
  const anchorProps = {
    ref,
    tabIndex: 0,
    color: 'link.main',
    underline: 'hover',
    // automatically adds rel="noreferrer" when target="_blank" for security
    rel: restProps.target === '_blank' ? 'noreferrer' : undefined,
    ...restProps,
  } as LinkProps;

  const isCCWebLink = /submit(.dev|.qa|).shutterstock/g.test(href);

  if (useNextLink === true) {
    return <MUILink href={href} {...anchorProps} component={NextLink} />;
  }

  return isCCWebLink || isExternalLink ? (
    <MUILink href={href} {...anchorProps} />
  ) : (
    <MUILink href={href} {...anchorProps} component={NextLink} />
  );
});
