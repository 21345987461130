import { forwardRef, useCallback } from 'react';

import { useAnalyticsContext, useAnalyticsPageSectionContext } from '@/lib/analytics';

const noop = () => {};

/**
 * Takes a functional component and wraps it in another function that support analytics for one handler only.
 * everything more complicated could be just handled case by case
 * calls methods from @shutterstock-private/analytics-wrapper AnalyticsWrapper class
 *
 * @param {function|string} Component - a functional component
 */
export const analyticsComponentWrapper = (Component) => {
  const NewComponent = forwardRef(
    (
      {
        clickTrack = undefined,
        labelTrack = undefined,
        eventName = 'onClick',
        clickTrackOptions = undefined,
        ...props
      },
      ref,
    ) => {
      const pageSection = useAnalyticsPageSectionContext();

      const originalEventHandler = props[eventName] || noop;
      const { analytics } = useAnalyticsContext();
      const newEventHandler = useCallback(
        (...args) => {
          if (clickTrack) {
            analytics.inlineClick(clickTrack, clickTrackOptions);
          } else if (labelTrack) {
            analytics.inlineClick(`${pageSection}.${labelTrack}`, clickTrackOptions);
          }

          return originalEventHandler(...args);
        },
        [clickTrack, analytics, originalEventHandler, clickTrackOptions, labelTrack, pageSection],
      );
      const newProps = {
        ...props,
        [eventName]: newEventHandler,
      };

      return <Component ref={ref} {...newProps} />;
    },
  );

  NewComponent.displayName = Component.displayName;

  return NewComponent;
};
