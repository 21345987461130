import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import { FC } from 'react';

import { EmptyStateWithDropzone } from '@/components/Portfolio/EmptySateWithDropzone/EmptyStateWithDropzone';
import { IconButton } from '@/components/WithTracking/IconButton';
import { CommonTrackActions, PortfolioPageSections } from '@/constants/analytics';
import { useNotifications } from '@/hooks/useNotifications';
import { CloseIcon } from '@/icons/CloseIcon';
import { PageSection } from '@/lib/analytics/react-data-tracking';

const styles: Record<string, SxProps<Theme>> = {
  modalWrapper: ({ breakpoints, spacing }) => ({
    alignItems: 'center',
    alignContent: { xs: 'flex-start', sm: 'center' },
    height: '100%',
    [breakpoints.down('sm')]: {
      mt: spacing(6),
    },
  }),
  closeButton: ({ spacing }) => ({
    position: 'absolute',
    right: spacing(3),
    top: spacing(3),
  }),
};

type Props = {
  isOpen: boolean;
  closeDialog: () => void;
};

export const UploaderDialog: FC<Props> = function UploaderDialog({ isOpen, closeDialog }) {
  const paperProps = {
    sx: {
      minWidth: '80vw',
      maxWidth: '80vw',
      minHeight: '90vh',
      maxHeight: '90vh',
      overflow: 'hidden',
      height: '100%',
    },
  };

  const { displayError } = useNotifications();

  return (
    <PageSection value={PortfolioPageSections.uploaderDialog}>
      <Dialog open={isOpen} onClose={closeDialog} PaperProps={paperProps}>
        <Grid container sx={styles.modalWrapper}>
          <DialogTitle>
            <IconButton sx={styles.closeButton} onClick={closeDialog} trackAction={CommonTrackActions.close}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <EmptyStateWithDropzone
            displayError={displayError as any}
            successfulUploadHandler={closeDialog}
            failedUploadHandler={closeDialog}
          />
        </Grid>
      </Dialog>
    </PageSection>
  );
};
