import { useCallback } from 'react';

import { CommonTrackActions } from '@/constants/analytics';
import { WarningOrErrorAnalyticData } from '@/hooks/analytics/types';
import { useAnalyticsContext } from '@/lib/analytics';

export const useWarningOrErrorEvent = () => {
  const { analytics } = useAnalyticsContext();

  return useCallback(
    ({ errorType, text }: WarningOrErrorAnalyticData) => {
      if (errorType && analytics) {
        analytics.error({
          errorType,
          eventAction: CommonTrackActions.errorOrWarning,
          eventCategory: 'userInteraction',
          eventLabel: text,
        });
      }
    },
    [analytics],
  );
};
