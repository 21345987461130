import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';

import { fetch } from '@/lib/fetch';

const fetcher = (url) => fetch.get(url).then((res) => res.data);

/**
 * Wraps `useSWR` hook using the `fetch` axios API instance.
 * @see {@link fetch}
 * @param {String|null} path - path to the API endpoint.
 * @param {Object} [options] - path to the API endpoint.
 * @returns An object containing the `useSWR` return values and a loading indicator:
 * - data: data for the given key resolved by fetcher (or undefined if not loaded)
 * - error: error thrown by fetcher (or undefined)
 * - isLoading: if the request is loading
 * - mutate(data?, shouldRevalidate?): function to mutate the cached data (details)
 */
export const useFetch = (path, options) => {
  const { isImmutable = false, ...restOptions } = options || {};

  const swrHook = isImmutable ? useSWRImmutable : useSWR;
  const { data, error, mutate } = swrHook(path, fetcher, restOptions);

  return {
    data,
    error,
    isLoading: !data && !error && !!path, // To conditionally call useSWR we need to pass null as the path, checking path truthiness here is required to return loading as false.
    mutate,
  };
};
