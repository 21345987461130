import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useCallback, useEffect, useState } from 'react';

import { ProfileInformation } from '@/components/ProfileInformation';
import { IconButton } from '@/components/WithTracking/IconButton';
import { CommonTrackActions, NavigationPageSections } from '@/constants/analytics';
import { SITE_HEADER_HEIGHT } from '@/constants/global';
import { CloseIcon } from '@/icons/CloseIcon';
import { PageSection } from '@/lib/analytics/react-data-tracking';
import { useBannerContext } from '@/providers/Banner/BannerProvider';

import { useSubMenuDrawer } from './hooks';
import { SubMenuList } from './SubMenuList';

/**
 * This function is a band aid for Drawer functionality that causes the header to be scrolled away from.
 * This will bring the header back into view. This function will only trigger when the drawer has been opened when the header is in view.
 */
const scrollToTopIfHeaderInView = () => window.scrollY === parseInt(SITE_HEADER_HEIGHT, 10) && window.scrollTo(0, 0);

export const closeHandler = (closeDrawer) => (_event, reason) => {
  scrollToTopIfHeaderInView();

  if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
    closeDrawer();
  }
};

const createStyles = ({ breakpoints, spacing }, isDrawerOpen, desktopHeightOffset, mobileHeightOffset) => ({
  drawerDesktop: {
    /*
  backdrop component doesn't share margin with root style like paper does. Backdrops has its' own
  margin. Root component needs margin otherwise it blocks SiteMenu button clicks
  */
    position: 'sticky',
    top: 0,
    height: isDrawerOpen ? `calc(100vh - ${desktopHeightOffset}px)` : '100%',
    [breakpoints.down('sm')]: {
      display: 'none',
    },
    margin: isDrawerOpen ? `${SITE_HEADER_HEIGHT} 0 0 ${spacing('xxl')}` : 0,
    '& .MuiDrawer-paper': {
      borderTop: '1px solid white',
      position: 'absolute',
      boxShadow: 'none',
      width: `calc(${spacing('xxl')} * 4)`,
    },
    '& .MuiBackdrop-root': {
      position: 'sticky',
      top: 0,
      height: '100%',
      margin: isDrawerOpen ? `${SITE_HEADER_HEIGHT} 0 0 ${spacing('xxl')}` : 0,
    },
  },
  drawerMobile: {
    mt: `${mobileHeightOffset}px`,
    '& .MuiDrawer-paper': {
      width: '100%',
      height: `calc(100% - ${mobileHeightOffset}px)`,
      mt: `${mobileHeightOffset}px`,
      boxShadow: 'none',
      overflow: 'scroll',
    },
    '& .MuiBackdrop-root': {
      mt: `${mobileHeightOffset}px`,
    },
  },
  closeButtonContainer: {
    display: { xs: 'none', md: 'block' },
    position: 'absolute',
    right: 0,
    m: spacing('s'),
  },
});

export const SubMenuDrawer = () => {
  const isMobileOrTabletView = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const theme = useTheme();
  const { drawerContentType, isDrawerOpen, closeDrawer, checkShouldTransition } = useSubMenuDrawer();
  const [scroll, setScrollY] = useState(0);
  const onScroll = useCallback(() => {
    setScrollY(window.scrollY);
  }, []);
  const { desktopMinHeight, mobileMinHeight } = useBannerContext();

  useEffect(() => {
    window.addEventListener('scroll', onScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', onScroll, { passive: true });
    };
  }, [onScroll]);
  let desktopHeightOffset = parseInt(SITE_HEADER_HEIGHT, 10) + desktopMinHeight - scroll;
  let mobileHeightOffset = parseInt(SITE_HEADER_HEIGHT, 10) + mobileMinHeight - scroll;

  if (desktopHeightOffset < 0) {
    desktopHeightOffset = 0;
  }

  if (mobileHeightOffset < 0) {
    mobileHeightOffset = 0;
  }

  const classes = createStyles(theme, isDrawerOpen, desktopHeightOffset, mobileHeightOffset);

  return (
    <PageSection value={NavigationPageSections.submenuDrawer}>
      <Drawer
        anchor="left"
        onClose={closeHandler(closeDrawer)}
        open={isDrawerOpen}
        hideBackdrop={isMobileOrTabletView}
        sx={isMobileOrTabletView ? classes.drawerMobile : classes.drawerDesktop}
        ModalProps={{
          keepMounted: true,
        }}
        SlideProps={{
          onExited: checkShouldTransition,
        }}
      >
        <Box sx={classes.closeButtonContainer}>
          <IconButton
            sx={classes.closeButtonContainer}
            onClick={() => closeDrawer() || scrollToTopIfHeaderInView()}
            trackAction={CommonTrackActions.close}
          >
            <CloseIcon fontSize="medium" sx={{ color: theme.palette.text.primary }} />
          </IconButton>
        </Box>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <ProfileInformation />
        </Box>
        <SubMenuList drawerContentType={drawerContentType} />
      </Drawer>
    </PageSection>
  );
};
