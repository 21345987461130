import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { ALLOWED_FILE_TYPES } from './constants';

/**
 * @typedef {import('@mui/material/styles').Theme} Theme
 * @type {import('@mui/system/styles').SxProps<Theme>}
 * @param {Theme} theme
 */
const dropzoneHighlight = ({ spacing, palette }) => ({
  content: '""',
  position: 'absolute',
  top: 1,
  bottom: 1,
  left: 1,
  right: 1,
  border: `${spacing('xxs')} solid ${palette.info.main}`,
  borderRadius: spacing('s'),
  backgroundColor: 'rgba(237, 247, 255, 0.6);',
});

export const Dropzone = ({ children, allowedTypes = ALLOWED_FILE_TYPES, onDropFile }) => {
  const fileTypes = allowedTypes || /* istanbul ignore next */ ALLOWED_FILE_TYPES;
  const [dropEnabled, setDropEnabled] = useState(false);

  const onDragLeave = () => setDropEnabled(false);

  /**
   * @param {DragEvent} e
   */
  const onDrag = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
    e.dataTransfer.effectAllowed = 'move';

    const isFile = e.dataTransfer.types.includes('Files');

    setDropEnabled(isFile);

    return isFile;
  };

  /**
   * @param {DragEvent} event
   */
  const onDrop = (e) => {
    e.preventDefault();
    const { dataTransfer } = e;

    if (!dropEnabled || !dataTransfer.types.includes('Files')) return;

    onDragLeave();

    const validFiles = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, l = dataTransfer.files.length; i < l; i++) {
      const file = dataTransfer.files[i];

      if (fileTypes.includes(file.type)) validFiles.push(file);
    }

    onDropFile(validFiles);
  };

  return (
    <Box
      data-testid="dropzone-container"
      sx={{ width: '100%', height: '100%' }}
      onDragEnter={onDrag}
      onDragOver={onDrag}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      {children}

      <Fade data-testid="dropzone-highlight" in={dropEnabled} unmountOnExit>
        <Box sx={dropzoneHighlight} />
      </Fade>
    </Box>
  );
};

Dropzone.propTypes = {
  allowedTypes: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  onDropFile: PropTypes.func.isRequired,
};
