import React from 'react';

import { DataTrackingContext } from './data-tracking.context';

interface PageSectionRef {
  current: PageSection;
}

interface PageSection {
  value: string;
  // eslint-disable-next-line react/no-unused-prop-types
  parent?: PageSectionRef;
}

export const PageSection = (props: React.PropsWithChildren<PageSection>) => {
  const { children, value } = props;

  return (
    <DataTrackingContext name="page_section" data={{ value }}>
      {children}
    </DataTrackingContext>
  );
};
