import { useContext } from 'react';

import { UploadsContext } from './UploadsContext';

export const useUploadsContext = () => {
  const context = useContext(UploadsContext);

  if (!context) {
    throw new Error('useUploadsContext must be used within a UploadsProvider');
  }

  return context;
};
