export const languages = [
  {
    code: 'cs',
    label: 'Čeština',
    translation_tag: 'czech_language',
  },
  {
    code: 'da',
    label: 'Dansk',
    translation_tag: 'danish_language',
  },
  {
    code: 'de',
    label: 'Deutsch',
    translation_tag: 'german_language',
  },
  {
    code: 'en',
    label: 'English',
    translation_tag: 'english_language',
  },
  {
    code: 'es',
    label: 'Español',
    translation_tag: 'spanish_language',
  },
  {
    code: 'fr',
    label: 'Français',
    translation_tag: 'french_language',
  },
  {
    code: 'it',
    label: 'Italiano',
    translation_tag: 'italian_language',
  },
  {
    code: 'hu',
    label: 'Magyar',
    translation_tag: 'hungarian_language',
  },
  {
    code: 'nl',
    label: 'Nederlands',
    translation_tag: 'dutch_language',
  },
  {
    code: 'nb',
    label: 'Norsk',
    translation_tag: 'norwegian_language',
  },
  {
    code: 'pl',
    label: 'Polski',
    translation_tag: 'polish_language',
  },
  {
    code: 'pt',
    label: 'Português',
    translation_tag: 'portuguese_language',
  },
  {
    code: 'fi',
    label: 'Suomi',
    translation_tag: 'finnish_language',
  },
  {
    code: 'sv',
    label: 'Svenska',
    translation_tag: 'swedish_language',
  },
  {
    code: 'tr',
    label: 'Türkçe',
    translation_tag: 'turkish_language',
  },
  {
    code: 'ru',
    label: 'Русский',
    translation_tag: 'russian_language',
  },
  {
    code: 'th',
    label: 'ไทย',
    translation_tag: 'thai_language',
  },
  {
    code: 'ko',
    label: '한국어',
    translation_tag: 'korean_language',
  },
  {
    code: 'ja',
    label: '日本語',
    translation_tag: 'japanese_language',
  },
  {
    code: 'zh',
    label: '简体中文',
    translation_tag: 'simplified_chinese_language',
  },
  {
    code: 'zh-Hant',
    label: '繁體中文',
    translation_tag: 'traditional_chinese_language',
  },
];
