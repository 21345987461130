module.exports = {
  END_APP_NAME: 'x-end-app-name',
  END_APP_VERSION: 'x-end-app-version',
  REQUEST_ID: 'x-request-id',
  SHUTTERSTOCK_SEARCH_ID: 'x-shutterstock-search-id',
  ACCEPT: 'Accept',
  CONTENT_TYPE: 'content-type',
  HOST: 'host',
  X_END_USER_COUNTRY: 'x-end-user-country',
  X_END_USER_VISIT_ID: 'x-end-user-visit-id',
  X_END_USER_VISITOR_ID: 'x-end-user-visitor-id',
  AUTHORIZATION: 'Authorization',
  X_END_USER_ANONYMOUS_ID: 'x-end-user-anonymous-id',
};
